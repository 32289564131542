import React from 'react';
import { View } from 'react-native';

import { Text } from 'react-native-paper';

import { sharedStyles } from '@core/styles/sharedStyles';

const NotFoundScreen = () => {
  return (
    <View style={[sharedStyles.center, sharedStyles.flex]}>
      <Text variant="displayLarge">404 not found</Text>
    </View>
  );
};

export default NotFoundScreen;
