import isQuestionAnswered from '@api/isQueryAnswered';

import React, { FC, useCallback, useState } from 'react';
import { Image, Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Auth } from 'aws-amplify';
import { ActivityIndicator, TextInput } from 'react-native-paper';
import { polyfill as polyfillFetch } from 'react-native-polyfill-globals/src/fetch';
import { polyfill as polyfillReadableStream } from 'react-native-polyfill-globals/src/readable-stream';
import 'text-encoding-polyfill';
import { v4 as uuidv4 } from 'uuid';

import { askQuestion } from '@api/ask';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isHeightMoreXS, isMobile, isWeb, screenWidth } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { SendMessage } from '@core/types';
import { stylesByPlatform } from '@core/utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sendIcon = require('@assets/send.png');

const poly = () => {
  if (isMobile) {
    polyfillFetch();
    polyfillReadableStream();
  }
};

interface InputMessageProps {
  sendMessage: ({ senderId, text, id }: SendMessage) => void;
  currentChatId: string;
}

const InputMessage: FC<InputMessageProps> = ({ sendMessage, currentChatId }) => {
  poly();
  const { t } = useLocalize();
  const [message, setMessage] = useState<string>('');
  const [height, setHeight] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSendMessage = useCallback(async () => {
    if (!message) return;

    const user = await Auth.currentAuthenticatedUser();
    const userId = user.username;

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    setIsLoading(true);
    Keyboard.dismiss();

    const messageId = uuidv4();

    sendMessage({
      id: `user_${messageId}`,
      senderId: 'user',
      text: message,
      scrollToEnd: true,
    });

    const prevMessage = message;
    setMessage('');
    await askQuestion({
      questionInput: message,
      token: token,
      chatId: currentChatId,
      callback: (answerText) => {
        sendMessage({
          id: `genivisor_${messageId}`,
          senderId: 'genivisor',
          text: answerText,
          scrollToEnd: true,
        });
      },
      errorCallBack: () => {
        console.log('error');
        sendMessage({
          id: `user_${messageId}`,
          senderId: 'user',
          text: prevMessage,
          scrollToEnd: true,
          error: true,
        });
      },
    })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then(async (r: string) => {
        try {
          const r_1 = await isQuestionAnswered({
            answer: r,
            query: prevMessage,
            token: token,
            errorCallBack: (error_1: Error) => {
              console.log('error isQuestionAnswered:', error_1);
            },
          });
          return r_1.is_answered;
        } catch (error_2) {
          console.log('error isQuestionAnswered:', error_2);
        }
      })
      .then((r_2) => {
        if (!r_2) {
          const id = uuidv4();
          sendMessage({
            id: `user_${id}`,
            senderId: 'genivisor',
            text: t('home.genivisor.nonAnswered'),
            scrollToEnd: true,
            nonAnsweredQuestion: true,
            userId: userId,
          });
        }
        return '';
      });
    setIsLoading(false);
  }, [message, sendMessage]);

  const handleChangeText = useCallback((text: string) => {
    setMessage(text);
  }, []);

  return (
    <View style={stylesByPlatform({ webStyle: styles.containerWeb })}>
      <View
        style={[
          sharedStyles.rowBetween,
          stylesByPlatform({ mobileStyle: styles.container }),
          stylesByPlatform({ webStyle: styles.centerWebData }),
        ]}
      >
        <TextInput
          mode="outlined"
          placeholder={t('home.input.message')}
          value={message}
          onChangeText={handleChangeText}
          onContentSizeChange={(event) => {
            setHeight(event.nativeEvent.contentSize.height);
          }}
          onKeyPress={(e) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (e.nativeEvent.key === 'Enter' && !e.nativeEvent?.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          multiline={true}
          activeOutlineColor={colors.purple2}
          placeholderTextColor={colors.gray2}
          style={[
            stylesByPlatform({ mobileStyle: styles.input, webStyle: styles.inputWeb }),
            styles.maxHeight,
          ]}
          outlineStyle={[
            styles.borderRadius,
            styles.maxHeight,
            // eslint-disable-next-line react-native/no-inline-styles
            isWeb && { height: height < 1109 ? Math.max(56, height) : 110, paddingTop: 8 },
          ]}
          contentStyle={[
            // eslint-disable-next-line react-native/no-inline-styles
            isWeb && { height: height < 110 ? Math.max(56, height) : 110, paddingTop: 8 },
          ]}
          // handleKeyDown={handleKeyDown}
          editable={!isLoading}
          maxLength={1000}
          textAlignVertical="center"
        />

        <Spacer width={16} />

        <TouchableOpacity
          onPress={handleSendMessage}
          style={styles.sendButton}
          disabled={isLoading}
        >
          {isLoading ? (
            <ActivityIndicator color={colors.white} size={24} />
          ) : (
            <Image source={sendIcon} style={styles.icon} />
          )}
        </TouchableOpacity>
      </View>

      {isWeb ? <Spacer height={12} /> : !isHeightMoreXS ? <Spacer height={12} /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    backgroundColor: colors.white,
    width: screenWidth - 96,
    paddingTop: 8,
    minHeight: 56,
  },
  inputWeb: {
    backgroundColor: colors.white,
    width: 650 - 48 - 16,
    paddingTop: 8,
    minHeight: 56,
  },
  borderRadius: { borderRadius: radii.button },
  maxHeight: { maxHeight: 126 },
  sendButton: {
    width: 48,
    height: 48,
    borderRadius: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.black,
  },
  icon: { width: 24, height: 24 },
  container: {
    borderTopColor: colors.purple3,
    borderTopWidth: 1,
    paddingHorizontal: 16,
    paddingTop: 12,
    alignItems: 'center',
  },
  containerWeb: {
    width: '100%',
    borderTopColor: colors.purple3,
    borderTopWidth: 1,
    paddingHorizontal: 16,
    paddingTop: 12,
    alignItems: 'center',
  },
  centerWebData: { width: 650 },
});

export default InputMessage;
