import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { FunctionComponent } from 'react';

import { RawIntlProvider } from 'react-intl';

import { Locale, Locales, createGlobalIntl, getLocaleMessages } from '@core/locales';
import Storage from '@core/storege';

interface LocaleContextProps {
  locale: Locale;
  changeLocale(locale: Locale): void;
  init(): Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LocaleContext = createContext<LocaleContextProps>();

const LOCALE_LOCAL_STORAGE_KEY = '@@Provider/Locale';

const LocaleProvider: FunctionComponent<PropsWithChildren> = (props) => {
  const [locale, setLocale] = useState<Locale>(Locales.en);

  const init = useCallback(async () => {
    const savedLocale = Storage.get(LOCALE_LOCAL_STORAGE_KEY) as Locale | undefined;
    if (savedLocale) setLocale(savedLocale);
  }, []);

  const intl = useMemo(() => {
    const messages = getLocaleMessages(locale);

    return createGlobalIntl({
      locale,
      messages,
      onError: (error) => console.error('Intl error: ', error),
    });
  }, [locale]);

  const changeLocale = useCallback<LocaleContextProps['changeLocale']>((newLocale) => {
    setLocale(newLocale);
    Storage.set(LOCALE_LOCAL_STORAGE_KEY, newLocale);
  }, []);

  return (
    <LocaleContext.Provider value={{ locale, changeLocale, init }}>
      <RawIntlProvider value={intl} {...props} />
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);

export default LocaleProvider;
