import { domain } from './config';

interface AskQuestionProps {
  query: string;
  token: string;
  answer: string;
  errorCallBack: (error: Error) => void;
}

interface Response {
  accuracy_rate: number;
  is_answered: boolean;
}

export default async function isQuestionAnswered({
  answer,
  query,
  token,
  errorCallBack,
}: AskQuestionProps): Promise<Response> {
  return await fetch(
    domain +
      '/is_question_answered?query=' +
      encodeURIComponent(query) +
      '&answer=' +
      encodeURIComponent(answer),
    {
      method: 'GET',
      headers: {
        token: token,
      },
    }
  )
    .then(async (response) => {
      return (await response.json()) as Response;
    })
    .catch((error) => {
      errorCallBack(error as Error);
      return { accuracy_rate: 0, is_answered: false };
    });
}
