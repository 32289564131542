import React, { FC, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { RadioButton, Text } from 'react-native-paper';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import { useLocale } from '@context/Locale';
import colors from '@core/colors/colors';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

interface LanguageProps extends LoggedInStackScreenProps<'Language'> {}

const Language: FC<LanguageProps> = ({ navigation }) => {
  const { t } = useLocalize();
  const { changeLocale, locale } = useLocale();

  const isUA = useMemo(() => {
    if (locale === 'ua') {
      return true;
    }
    return false;
  }, [locale]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('settings.button.language')}
        isBurgerMenu={false}
        isBack={true}
        navigation={navigation}
      />

      <View
        style={stylesByPlatform({ webStyle: sharedStyles.center, mobileStyle: sharedStyles.flex })}
      >
        <View
          style={stylesByPlatform({
            webStyle: styles.centerWeb,
            mobileStyle: sharedStyles.padding16,
          })}
        >
          <TouchableOpacity
            onPress={() => changeLocale('en')}
            style={[
              sharedStyles.rowBetween,
              sharedStyles.paddingHorizontal16,
              styles.button,
              sharedStyles.rowAlignCenter,
            ]}
          >
            <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
              <RadioButton.Android
                value={''}
                status={isUA ? 'unchecked' : 'checked'}
                onPress={() => changeLocale('en')}
              />

              <Spacer width={12} />

              <Text variant={'bodyLarge'} style={{ color: colors.black }}>
                English
              </Text>
            </View>
          </TouchableOpacity>

          <Spacer height={8} />

          <TouchableOpacity
            onPress={() => changeLocale('ua')}
            style={[
              sharedStyles.rowBetween,
              sharedStyles.paddingHorizontal16,
              styles.button,
              sharedStyles.rowAlignCenter,
            ]}
          >
            <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
              <RadioButton.Android
                value={''}
                status={!isUA ? 'unchecked' : 'checked'}
                onPress={() => changeLocale('ua')}
              />

              <Spacer width={12} />

              <Text variant={'bodyLarge'} style={{ color: colors.black }}>
                Українська
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  button: { backgroundColor: colors.purple4, height: 56, borderRadius: radii.button },
  centerWeb: { width: 650, paddingTop: 16 },
});

export default Language;
