import React, { FC, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { Button, HelperText, Text, TextInput } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

interface ForgotPasswordProps extends LoggedOutStackScreenProps<'ForgotPassword'> {}

const ForgotPassword: FC<ForgotPasswordProps> = ({ navigation, route }) => {
  const { t } = useLocalize();
  const { forgotPassword } = useAmplify();
  const [email, setEmail] = useState<string>(route.params?.email || '');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeEmail = useCallback((text: string) => {
    setEmail(text);
  }, []);

  const handleGoBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleGoToVerifyEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await forgotPassword(email);
      if (data) {
        navigation.navigate('VerifyEmail', { email });
      } else {
        setError(t('login.error.wrong'));
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [email, forgotPassword, navigation, t]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('forgetPassword.header.forgetPassword')}
        navigation={navigation}
        isBack
        isBurgerMenu={false}
      />

      <View style={stylesByPlatform({ webStyle: sharedStyles.center })}>
        <View style={[sharedStyles.padding16, stylesByPlatform({ webStyle: styles.centerWeb })]}>
          <Text variant={'bodyLarge'}>{t('forgetPassword.title.forgetPassword')}</Text>

          <Spacer height={8} />

          <TextInput
            mode="outlined"
            label={t('login.input.email')}
            value={email}
            placeholder={t('login.input.email')}
            onChangeText={handleChangeEmail}
            activeOutlineColor={colors.purple2}
            placeholderTextColor={colors.gray2}
            style={{ backgroundColor: colors.white }}
            outlineStyle={{ borderRadius: radii.button }}
            editable={!isLoading}
            autoCapitalize={'none'}
          />

          {error ? (
            <HelperText type="error" visible={Boolean(error)} style={styles.helper}>
              {error}
            </HelperText>
          ) : null}

          <Spacer height={8} />

          <Button
            mode="contained"
            onPress={handleGoToVerifyEmail}
            buttonColor={colors.black}
            style={styles.button}
            loading={isLoading}
            disabled={isLoading}
          >
            <Text style={[{ color: colors.white }, styles.buttonText]}>
              {t('forgetPassword.button.submit')}
            </Text>
          </Button>

          <Spacer height={22} />

          <TouchableOpacity onPress={handleGoBack} style={sharedStyles.center}>
            <Text variant={'titleMedium'} style={styles.textLogin}>
              {t('forgetPassword.button.goToLogIn')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 8 },
  textLogin: { color: colors.purple2, fontWeight: '600', paddingTop: 3 },
  helper: { textAlign: 'center' },
});

export default ForgotPassword;
