import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { isWeb, isWebMobile } from './constants';

interface stylesByPlatformProps {
  webStyle?: StyleProp<TextStyle> | StyleProp<ViewStyle> | StyleProp<ImageStyle> | undefined;
  mobileStyle?: StyleProp<TextStyle> | StyleProp<ViewStyle> | StyleProp<ImageStyle> | undefined;
}

export const stylesByPlatform = ({
  webStyle = undefined,
  mobileStyle = undefined,
}: stylesByPlatformProps) => {
  if (isWebMobile) {
    return mobileStyle;
  }
  if (isWeb) {
    return webStyle;
  }
  return mobileStyle;
};

export const truncateChatName = (chatName: string | undefined, defaultName: string) => {
  if (chatName) {
    if (chatName.length <= 40) {
      return chatName;
    } else {
      return `${chatName.slice(0, 37)}...`;
    }
  } else {
    return defaultName;
  }
};
