import React, { FC, useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import moment from 'moment';
import {ActivityIndicator, Avatar, Text} from 'react-native-paper';
import { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { v4 as uuidv4 } from 'uuid';

import { askExternal } from '@api/askExternal';
import Dislike from '@components/Dislike';
import FeedbackButton from '@components/FeedbackButton';
import FeedbackModal from '@components/FeedbackModal';
import Like from '@components/Like';
import Spacer from '@components/Spacer';
import { useUser } from '@context/User';
import colors from '@core/colors/colors';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { MessageInterface, SendMessage } from '@core/types';
import {Auth} from "aws-amplify";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoAvatar = require('@assets/logo-avatar.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const refreshIcon = require('@assets/refresh.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const userEmpty = require('@assets/user.png');

interface MessageProps {
  message: MessageInterface;
  sendMessage: ({ senderId, text, id }: SendMessage) => void;
  deleteLastMessage: () => void;
  lastUserQuestion: string;
}

const Message: FC<MessageProps> = ({
  message,
  sendMessage,
  deleteLastMessage,
  lastUserQuestion,
}) => {
  const { user } = useUser();
  const offsetRotate = useSharedValue(0);
  const { t } = useLocalize();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    text,
    like,
    dislike,
    sender,
    date,
    feedback,
    error,
    errorMsg,
    showAskExternalBtn,
    userId,
    currentChatId,
  } = message;

  const handleLike = useCallback(
    (message: MessageInterface) => {
      sendMessage({
        ...message,
        senderId: 'genivisor',
        like: !message.like,
        dislike: false,
      });
    },
    [sendMessage]
  );

  const handleDislike = useCallback(
    (message: MessageInterface) => {
      if (!message.dislike) {
        setModalVisible(true);
      }
      sendMessage({
        ...message,
        senderId: 'genivisor',
        like: false,
        dislike: !message.dislike,
      });
    },
    [sendMessage]
  );

  const handleFeedback = useCallback(
    (message: MessageInterface, newFeedback: string) => {
      if (!message.dislike) {
        setModalVisible(true);
      }
      sendMessage({
        ...message,
        senderId: 'genivisor',
        feedback: newFeedback,
      });
      setModalVisible(false);
    },
    [sendMessage]
  );

  const isUserSender = useMemo(() => {
    if (sender.id !== 'genivisor') {
      return true;
    }
    return false;
  }, [sender.id]);

  const handleRefetchMessage = useCallback(() => {
    offsetRotate.value = withSpring(offsetRotate.value + 360);
    setTimeout(() => {
      offsetRotate.value = withSpring(offsetRotate.value - 360);
    }, 2000);
  }, [offsetRotate]);

  const time = useMemo(() => {
    const isToday = moment(date).isSame(new Date().valueOf(), 'day');

    if (isToday) {
      return moment(date).format('HH:mm');
    }
    return moment(date).format('DD/MM/YY HH:mm');
  }, [date]);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: offsetRotate.value + 'deg' }],
    };
  });

  const askExternalHandler = useCallback(async () => {
    setIsLoading(true);
    const messageId = uuidv4();

    const session = await Auth.currentSession();

    const token = session.getIdToken().getJwtToken();

    await askExternal({
      questionInput: lastUserQuestion,
      chatId: currentChatId,
      token: token,
      callback: (answerText: string) => {
        setIsLoading(false);
        deleteLastMessage();
        sendMessage({
          id: `user_${messageId}`,
          senderId: 'user',
          text: t('home.genivisor.askExternal'),
          scrollToEnd: true,
        });
        sendMessage({
          id: `genivisor_${messageId}`,
          senderId: 'genivisor',
          text: answerText,
          scrollToEnd: true,
        });
      },
      errorCallBack: () => {
        console.log('error');
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <View style={[styles.message, isUserSender && styles.userSender]}>
      {isUserSender ? (
        <View style={sharedStyles.rowBetween}>
          <Text style={styles.time}>{time}</Text>

          <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
            <Avatar.Image
              size={24}
              source={user?.attributes?.picture ? { uri: user.attributes.picture } : userEmpty}
            />

            <Spacer width={8} />

            <Text numberOfLines={1} style={styles.textName}>{`${(
              sender?.firstName || ''
            ).toUpperCase()} ${(sender?.lastName || '').toUpperCase()}`}</Text>
          </View>
        </View>
      ) : (
        <View style={sharedStyles.rowBetween}>
          <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
            <Avatar.Image size={24} source={logoAvatar} />

            <Spacer width={8} />

            <Text numberOfLines={1} style={styles.textName}>{`${'Genivisor'.toUpperCase()}`}</Text>
          </View>

          <Text style={styles.time}>{time}</Text>
        </View>
      )}

      <Spacer height={8} />

      {error ? (
        <View>
          <Text variant={'titleLarge'} style={styles.errorText}>
            {errorMsg || t('home.error.tryAgain')}
          </Text>

          {/* <Spacer height={16} />

          <TouchableOpacity onPress={handleRefetchMessage} style={sharedStyles.rowCenter}>
            <Animated.Image source={refreshIcon} style={[styles.icon, animatedStyles]} />

            <Spacer width={12} />

            <Text variant={'titleMedium'}>{t('home.error.message')}</Text>
          </TouchableOpacity> */}
        </View>
      ) : (
        <Text variant={'bodyLarge'}>
          {text}

          {showAskExternalBtn && (
            <Text onPress={askExternalHandler}>
              <Text>{' - '}</Text>
              {/* eslint-disable-next-line react-native/no-inline-styles */}
              {!isLoading ? (
                <Text style={{ textDecorationLine: 'underline' }}>
                  {t('home.genivisor.askExternalButton')}
                </Text>
              ) : (
                <ActivityIndicator color={colors.black} size={14} />
              )}
            </Text>
          )}
        </Text>
      )}

      {isUserSender ? null : (
        <>
          <Spacer height={8} />

          <View style={sharedStyles.rowBetween}>
            <View style={sharedStyles.row}>
              <Like onPress={() => handleLike(message)} isActive={like} />

              <Dislike onPress={() => handleDislike(message)} isActive={dislike} />
            </View>

            <FeedbackButton onPress={() => setModalVisible(true)} isActive={Boolean(feedback)} />

            <FeedbackModal
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
              onSend={(newFeedback) => {
                handleFeedback(message, newFeedback);
              }}
              defaultFeedback={feedback || ''}
              isLoading={false}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  errorText: { color: colors.red, fontWeight: '400' },
  textName: { color: colors.purple5, fontSize: 12, fontWeight: '600' },
  userSender: { backgroundColor: colors.purple4 },
  message: { borderRadius: radii.button, padding: 16, backgroundColor: colors.gray3 },
  icon: { width: 24, height: 24 },
  time: { fontWeight: '400', color: colors.purple5, fontSize: 13 },
});

export default Message;
