import { domain } from './config';
export const askExternal = async ({
  questionInput,
  token,
  callback,
  errorCallBack,
  chatId,
}: {
  questionInput: string;
  token: string;
  chatId: string;
  callback: (answerText: string) => void;
  errorCallBack: () => void;
}) => {
  try {
    const response = await fetch(
      `${domain}/ask_external?query=${encodeURIComponent(
        questionInput
      )}&dialog_id=${encodeURIComponent(chatId)}`,
      {
        method: 'GET',
        headers: {
          token: token,
        },
      }
    );

    const reader = response.body?.getReader();
    let answerText = '';
    while (true) {
      const stream = await reader?.read();

      if (stream?.done) {
        break;
      }

      answerText = answerText + new TextDecoder().decode(stream?.value);
      callback(answerText);
    }
  } catch (error) {
    console.log(error);
    errorCallBack();
  }
};
