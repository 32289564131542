import React, { FC, useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

const activeDislikeIcon = require('@assets/active-dislike.png');
const dislikeIcon = require('@assets/dislike.png');

interface DislikeProps {
  onPress: () => void;
  isActive: boolean;
}
const Dislike: FC<DislikeProps> = ({ onPress, isActive }) => {
  const offsetScale = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offsetScale.value }],
    };
  });

  const handlePressAndAnimation = useCallback(() => {
    offsetScale.value = withSpring(4);
    onPress();
    setTimeout(() => {
      offsetScale.value = withSpring(-4);
    }, 200);
    setTimeout(() => {
      offsetScale.value = withSpring(4);
    }, 400);
    setTimeout(() => {
      offsetScale.value = withSpring(0);
    }, 800);
  }, [offsetScale, onPress]);

  return (
    <TouchableOpacity onPress={handlePressAndAnimation}>
      <Animated.Image
        source={isActive ? activeDislikeIcon : dislikeIcon}
        style={[styles.icon, animatedStyles]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: { width: 44, height: 44 },
});

export default Dislike;
