import React, { FC, useCallback } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { DrawerNavigationProp, useDrawerStatus } from '@react-navigation/drawer';
import { Text } from 'react-native-paper';

import colors from '@core/colors/colors';
import { sharedStyles } from '@core/styles/sharedStyles';

import Spacer from './Spacer';
import { LoggedInStackParamList, LoggedOutStackScreenProps } from '../navigation/navigation';

const arrowLeftIcon = require('@assets/arrow-left.png');
const burgerIcon = require('@assets/burger-menu.png');

interface HeaderProps {
  title: string;
  isBurgerMenu?: boolean;
  navigation: DrawerNavigationProp<LoggedInStackParamList | LoggedOutStackScreenProps>;
  isBack?: boolean;
}
const Header: FC<HeaderProps> = ({ title, isBurgerMenu = true, navigation, isBack = false }) => {
  const isDrawerOpen = isBurgerMenu ? useDrawerStatus() : false;

  const handleDrawer = useCallback(() => {
    if (isDrawerOpen === 'open') {
      navigation.closeDrawer();
    } else {
      navigation.openDrawer();
    }
  }, [isDrawerOpen, navigation]);

  const handleGoBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View
      style={[sharedStyles.row, styles.header, sharedStyles.padding16, sharedStyles.alignCenter]}
    >
      {isBurgerMenu ? (
        <TouchableOpacity onPress={handleDrawer}>
          <Image source={burgerIcon} style={styles.burgerIcon} />
        </TouchableOpacity>
      ) : null}

      {isBack ? (
        <TouchableOpacity onPress={handleGoBack}>
          <Image source={arrowLeftIcon} style={styles.burgerIcon} />
        </TouchableOpacity>
      ) : null}

      <Spacer width={24} />

      <Text variant={'titleMedium'} style={styles.title}>
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  burgerIcon: { width: 24, height: 24 },
  header: {
    backgroundColor: colors.purple4,
    borderBottomColor: colors.purple3,
    borderBottomWidth: 1,
  },
  title: { fontWeight: '700', fontSize: 18 },
});

export default Header;
