import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Text } from 'react-native-paper';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';

const logoIcon = require('@assets/logo.png');

const EmptyChat = () => {
  const { t } = useLocalize();
  return (
    <View style={sharedStyles.center}>
      <Spacer height={60} />

      <Image source={logoIcon} style={styles.logo} />

      <Spacer height={22} />

      <Text style={styles.title} variant={'titleMedium'}>
        {t('home.page.title')}
      </Text>

      <Spacer height={22} />

      <View style={styles.descriptionContainer}>
        <Text>{t('home.page.description1')}</Text>
      </View>

      <Spacer height={8} />

      <View style={styles.descriptionContainer}>
        <Text>{t('home.page.description2')}</Text>
      </View>

      <Spacer height={8} />

      <View style={styles.descriptionContainer}>
        <Text>{t('home.page.description3')}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logo: { width: 149, height: 36 },
  title: { textAlign: 'center' },
  descriptionContainer: {
    padding: 16,
    backgroundColor: colors.purple4,
    borderRadius: radii.button,
    width: '100%',
  },
});

export default EmptyChat;
