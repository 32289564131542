import React, { FC, useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, HelperText, Text } from 'react-native-paper';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';
import {useUser} from "@context/User";
import {domain} from "@api/config";

interface DeleteAccountProps extends LoggedInStackScreenProps<'DeleteAccount'> {}

const DeleteAccount: FC<DeleteAccountProps> = ({ navigation }) => {
  const { t } = useLocalize();
  const { deleteAccount } = useAmplify();
  const { user } = useUser();
  const [deleteUserLoading, setDeleteUserLoading] = useState<boolean>(false);
  const [deleteUserError, setDeleteUserError] = useState<string>('');

  const handleDeleteUser = useCallback(async () => {
    setDeleteUserLoading(true);
    setDeleteUserError('');
    try {
      // Attempt to delete the authenticated user
      await fetch(domain + '/dialogs/', { method: 'DELETE', headers: { token: user.token } });
      await deleteAccount();
      navigation.navigate('LoggedOutStack');
    } catch (err) {
      const error = err as Error;

      // Handle different error messages
      if (error.message === 'User is not authenticated') {
        navigation.navigate('LoggedOutStack');
        setDeleteUserError(t('settings.error.userNotAuthenticated'));
      } else if (error.message === 'User deletion failed') {
        setDeleteUserError(t('settings.error.userDeletionFailed'));
      } else {
        setDeleteUserError(t('settings.error.unexpectedError'));
      }
    }
    setDeleteUserLoading(false);
  }, [navigation]);

  const handleGoBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('settings.button.deleteAccount')}
        isBurgerMenu={false}
        isBack={true}
        navigation={navigation}
      />

      <View
        style={stylesByPlatform({ webStyle: sharedStyles.center, mobileStyle: sharedStyles.flex })}
      >
        <View
          style={[
            stylesByPlatform({
              webStyle: styles.centerWeb,
              mobileStyle: sharedStyles.padding16,
            }),
            sharedStyles.flex,
          ]}
        >
          <Text variant={'bodyMedium'} style={styles.description}>
            {t('settings.description.deleteAccount')}
          </Text>

          <Spacer height={24} />

          <Text variant={'titleMedium'} style={styles.title}>
            {t('settings.title.deleteAccount')}
          </Text>

          <Spacer height={16} />

          <Text variant={'bodySmall'} style={styles.note}>
            {t('settings.note.deleteAccount')}
          </Text>

          <Spacer height={16} />

          {deleteUserError ? (
            <>
              <HelperText type="error" visible={Boolean(deleteUserError)} style={styles.helper}>
                {deleteUserError}
              </HelperText>
            </>
          ) : null}

          <Button
            mode="contained"
            onPress={handleDeleteUser}
            buttonColor={colors.red}
            style={styles.button}
            loading={deleteUserLoading}
          >
            <Text style={[{ color: colors.white }, styles.buttonText]}>
              {t('settings.Button.deleteAccount')}
            </Text>
          </Button>

          <Spacer height={8} />

          <Button
            mode="contained"
            onPress={handleGoBack}
            buttonColor={colors.black}
            style={styles.button}
          >
            <Text style={[{ color: colors.white }, styles.buttonText]}>
              {t('settings.Button.notDeleteAccount')}
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 8 },
  helper: { textAlign: 'center' },
  description: { textAlign: 'center' },
  title: { textAlign: 'center' },
  note: { textAlign: 'center', color: colors.purple5 },
  centerWeb: { width: 650, paddingTop: 16 },
});

export default DeleteAccount;
