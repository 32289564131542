import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { DrawerNavigationState } from '@react-navigation/routers/lib/typescript/src/DrawerRouter';
import { ParamListBase } from '@react-navigation/routers/lib/typescript/src/types';
import { Drawer, DrawerCollapsedItemProps, DrawerItemProps } from 'react-native-paper';

import colors from '@core/colors/colors';
import { isMobile, isWebMobile } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';

import Spacer from './Spacer';

const historyIcon = require('@assets/history.png');
const newChatIcon = require('@assets/new-chat.png');
const settingsIcon = require('@assets/settings.png');

interface DrawerPlatformProps extends DrawerCollapsedItemProps, DrawerItemProps {}

const DrawerPlatform = (props: DrawerPlatformProps) => {
  if (isMobile || isWebMobile) {
    return <Drawer.CollapsedItem {...props} />;
  }
  return (
    <Drawer.Item
      theme={props.theme}
      icon={props.icon}
      label={props?.label || ''}
      style={props.style}
      onPress={props.onPress}
    />
  );
};

const CircleDrawerContent = ({
  navigation,
  state,
}: {
  navigation: DrawerNavigationHelpers;
  state: DrawerNavigationState<ParamListBase>;
}) => {
  const { t } = useLocalize();

  const stateName = useMemo(() => {
    return state.routes[state.index]?.name || '';
  }, [state.index, state.routes]);

  const handleGoTo = useCallback(
    (link: string, params?: object) => {
      navigation.navigate(link, params);
    },
    [navigation]
  );

  return (
    <View>
      <Spacer height={16} />

      <DrawerPlatform
        label={t('home.title.newChat')}
        style={stateName === 'Home' && styles.activeItem}
        focusedIcon={newChatIcon}
        icon={newChatIcon}
        active={stateName === 'Home'}
        onPress={() => handleGoTo('Home', { chatId: '' })}
        theme={{
          fonts: {
            labelMedium: {
              fontSize: 10,
            },
          },
        }}
      />

      <Spacer height={16} />

      <DrawerPlatform
        label={t('history.title.history')}
        style={stateName === 'History' && styles.activeItem}
        icon={historyIcon}
        focusedIcon={historyIcon}
        active={stateName === 'History'}
        onPress={() => handleGoTo('History')}
        theme={{
          fonts: {
            labelMedium: {
              fontSize: 10,
              width: '100%',
            },
          },
        }}
      />

      <Spacer height={16} />

      <DrawerPlatform
        label={t('settings.title.settings')}
        style={stateName === 'SettingsStack' && styles.activeItem}
        icon={settingsIcon}
        focusedIcon={settingsIcon}
        active={stateName === 'SettingsStack'}
        onPress={() => handleGoTo('SettingsStack')}
        theme={{
          fonts: {
            labelMedium: {
              fontSize: 10,
            },
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  activeItem: { backgroundColor: colors.purple },
});

export default memo(CircleDrawerContent);
