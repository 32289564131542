import React, { FunctionComponent } from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaView } from 'react-native-safe-area-context';

import VerifyEmailPage from '@app/src/screens/VerifyEmail';
import colors from '@core/colors/colors';
import { sharedStyles } from '@core/styles/sharedStyles';

import { LoggedOutStackParamList } from './navigation';
import { commonNavigationOptions } from './options';
import DeepLinkLoading from '../screens/DeepLinkLoading';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ForgotPassword/ResetPassword';
import ResetPasswordSuccess from '../screens/ForgotPassword/ResetPasswordSuccess';
import VerifyEmail from '../screens/ForgotPassword/VerifyEmail';
import Login from '../screens/logIn';
import SignUp from '../screens/signUp';
import Start from '../screens/start';

const LoggedOutStack = createNativeStackNavigator<LoggedOutStackParamList>();

const LoggedOutStackNavigation: FunctionComponent = () => {
  return (
    <SafeAreaView style={[sharedStyles.flex, { backgroundColor: colors.white }]}>
      <LoggedOutStack.Navigator screenOptions={commonNavigationOptions}>
        <LoggedOutStack.Screen name="Start" component={Start} />

        <LoggedOutStack.Screen name="LogIn" component={Login} />

        <LoggedOutStack.Screen name="SignUp" component={SignUp} />

        <LoggedOutStack.Screen name="ForgotPassword" component={ForgotPassword} />

        <LoggedOutStack.Screen name="VerifyEmail" component={VerifyEmail} />

        <LoggedOutStack.Screen name="ResetPassword" component={ResetPassword} />

        <LoggedOutStack.Screen name="ResetPasswordSuccess" component={ResetPasswordSuccess} />

        <LoggedOutStack.Screen name="DeepLinkLoading" component={DeepLinkLoading} />

        <LoggedOutStack.Screen name="VerifyEmailPage" component={VerifyEmailPage} />
      </LoggedOutStack.Navigator>
    </SafeAreaView>
  );
};

export default LoggedOutStackNavigation;
