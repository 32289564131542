import React, { FC, useCallback, useState } from 'react';
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Button, HelperText, Text, TextInput } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const appleLogoIcon = require('@assets/apple-logo.png');
const eyeIcon = require('@assets/eye.png');
const googleLogoIcon = require('@assets/google-logo.png');
const logoIcon = require('@assets/logo.png');

interface LoginProps extends LoggedOutStackScreenProps<'LogIn'> {}

const LogIn: FC<LoginProps> = ({ navigation }) => {
  const { t } = useLocalize();
  const { signInApple, signInGoogle, signIn } = useAmplify();
  const [error, setError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [loadingApple, setLoadingApple] = useState<boolean>(false);
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLogInGoogle = useCallback(async () => {
    setError('');
    setLoadingGoogle(true);
    const result = await signInGoogle();
    if (result) {
      navigation.replace('DeepLinkLoading');
    } else {
      setError(t('login.error.wrong'));
    }
    setLoadingGoogle(false);
  }, [navigation, signInGoogle, t]);

  const handleLogInApple = useCallback(async () => {
    setError('');
    setLoadingApple(true);
    const result = await signInApple();
    if (result) {
      navigation.replace('DeepLinkLoading');
    } else {
      setError(t('login.error.wrong'));
    }
    setLoadingApple(false);
  }, [navigation, signInApple, t]);

  const handleNavigateToSignUp = useCallback(() => {
    navigation.navigate('SignUp');
  }, [navigation]);

  const handleChangeEmail = useCallback((text: string) => {
    setPasswordError(false);
    setEmail(text);
  }, []);

  const handleChangePassword = useCallback((text: string) => {
    setPasswordError(false);
    setPassword(text);
  }, []);

  const handleSecurePassword = useCallback(() => {
    setSecureTextEntry(!secureTextEntry);
  }, [secureTextEntry]);

  const handleLogIn = useCallback(async () => {
    setLoadingSignUp(true);
    try {
      const user = await signIn({ username: email, password });
      if (user) {
        navigation.replace('LoggedInStack');
      } else {
        setPasswordError(true);
      }
    } catch (err) {
      const error = err as Error;
      if (error.message === 'User is not confirmed.') {
        navigation.replace('VerifyEmailPage', { email });
      }
      if (error.message === 'Incorrect username or password.') {
        setPasswordError(true);
      }
      if (error.message === 'User does not exist.') {
        setError(t('login.error.userNotExist'));
      }
    }
    setLoadingSignUp(false);
  }, [email, navigation, password, signIn]);

  const handleNavigateToForgotPassword = useCallback(() => {
    navigation.navigate('ForgotPassword', { email: email });
  }, [email, navigation]);

  return (
    <ScrollView
      style={[sharedStyles.flex, sharedStyles.paddingHorizontal16, sharedStyles.backgroundColor]}
      contentContainerStyle={[
        sharedStyles.spaceBetween,
        stylesByPlatform({ webStyle: sharedStyles.center }),
      ]}
    >
      <View style={[sharedStyles.center, stylesByPlatform({ webStyle: styles.centerWeb })]}>
        <Spacer height={100} />

        <Image source={logoIcon} style={styles.logo} />

        <Spacer height={22} />

        <Text variant="titleMedium" style={{ color: colors.black }}>
          Welcome back to Genivisor!
        </Text>
      </View>

      <View style={stylesByPlatform({ webStyle: styles.centerWeb })}>
        <Text variant={'bodyLarge'} style={sharedStyles.textCenter}>
          {t('login.text.continue')}
        </Text>

        <Spacer height={8} />

        <TextInput
          mode="outlined"
          label={t('login.input.email')}
          value={email}
          placeholder={t('login.input.email')}
          onChangeText={handleChangeEmail}
          activeOutlineColor={colors.purple2}
          placeholderTextColor={colors.gray2}
          style={{ backgroundColor: colors.white, height: 40 }}
          outlineStyle={{ borderRadius: radii.button }}
          autoCapitalize={'none'}
          dense={true}  //
        />

        <Spacer height={8} />

        <TextInput
          mode="outlined"
          label={t('login.input.password')}
          value={password}
          placeholder={t('login.input.password')}
          onChangeText={handleChangePassword}
          secureTextEntry={secureTextEntry}
          right={
            <TextInput.Icon icon={eyeIcon} color={colors.gray2} onPress={handleSecurePassword} />
          }
          activeOutlineColor={colors.purple2}
          placeholderTextColor={colors.gray2}
          style={{ backgroundColor: colors.white, height: 40 }}
          outlineStyle={{ borderRadius: radii.button }}
          autoCapitalize={'none'}
          dense={true}  // Reduces internal padding// Set height here
        />

        {passwordError ? (
          <HelperText type="error" visible={Boolean(passwordError)} style={styles.helper}>
            {t('login.error.text')}
          </HelperText>
        ) : null}

        <Spacer height={8} />

        <Button
          mode="contained"
          onPress={handleLogIn}
          buttonColor={colors.black}
          style={styles.button}
          loading={loadingSignUp}
        >
          <Text style={[{ color: colors.white }, styles.buttonText]}>
            {t('login.button.login')}
          </Text>
        </Button>

        <Spacer height={16} />

        <TouchableOpacity onPress={handleNavigateToForgotPassword} style={sharedStyles.center}>
          <Text variant={'bodyLarge'} style={styles.textLogin}>
            {t('login.text.forgotPassword')}
          </Text>
        </TouchableOpacity>

        <Spacer height={16} />

        <View style={sharedStyles.rowCenter}>
          <Text variant={'bodyLarge'} style={[sharedStyles.textCenter, sharedStyles.center]}>
            {t('login.text.yet')}
          </Text>

          <Spacer width={4} />

          <TouchableOpacity onPress={handleNavigateToSignUp}>
            <Text variant={'bodyLarge'} style={styles.textLogin}>
              {t('login.button.signup')}
            </Text>
          </TouchableOpacity>
        </View>

        <Spacer height={24} />

        <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
          <View style={styles.border} />

          <Spacer width={16} />

          <Text variant={'bodyLarge'}>{t('login.text.or')}</Text>

          <Spacer width={16} />

          <View style={styles.border} />
        </View>

        <Spacer height={24} />

        <View style={stylesByPlatform({ webStyle: sharedStyles.center })}>
          <View style={stylesByPlatform({ webStyle: styles.centerButtonWeb })}>
            <TouchableOpacity
              onPress={handleLogInGoogle}
              style={[styles.button, sharedStyles.center, styles.buttonGoogle]}
            >
              <View style={[sharedStyles.center, sharedStyles.row]}>
                <Image source={googleLogoIcon} style={styles.icon} />

                <Spacer width={12} />

                <Text style={[{ color: colors.black }, styles.buttonText]}>
                  {t('login.button.loginGoogle')}
                </Text>
              </View>
            </TouchableOpacity>

            <Spacer height={8} />

            {Platform.OS !== 'android' ? (
              <Button
                mode="contained"
                onPress={handleLogInApple}
                buttonColor={colors.black}
                style={styles.button}
                labelStyle={sharedStyles.center}
                loading={loadingApple}
              >
                <View style={[sharedStyles.center, sharedStyles.row]}>
                  <Image source={appleLogoIcon} style={styles.icon} />

                  <Spacer width={12} />

                  <Text style={[{ color: colors.white }, styles.buttonText]}>
                    {t('login.button.loginApple')}
                  </Text>
                </View>
              </Button>
            ) : null}

            {error ? (
              <>
                <Spacer height={4} />

                <HelperText type="error" visible={Boolean(error)} style={styles.helper}>
                  {error}
                </HelperText>
              </>
            ) : (
              <Spacer height={32} />
            )}
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  centerButtonWeb: { width: 343, justifyContent: 'center' },
  logo: { width: 179, height: 42 },
  button: { height: 56, borderRadius: 100 },
  buttonGoogle: { borderColor: colors.gray, borderWidth: 1, borderRadius: 50 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 10 },
  icon: { width: 24, height: 24 },
  helper: { textAlign: 'center' },
  border: { backgroundColor: colors.gray, height: 1, flex: 1 },
  textLogin: { color: colors.purple2, fontWeight: '600' },
});

export default LogIn;
