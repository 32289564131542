/* eslint-disable react-native/no-raw-text */
import React, { useCallback, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Text } from 'react-native-paper';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isWebMobile, screenWidth } from '@core/constants';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const frame4Icon = require('@assets/LandingPage/frame4.png');
const minusIcon = require('@assets/LandingPage/minus.png');
const plusIcon = require('@assets/LandingPage/plus.png');

interface caseFAQ {
  case1: boolean;
  case2: boolean;
  case3: boolean;
  case4: boolean;
  case5: boolean;
  case6: boolean;
}
const FAQ = ({ setFAQY }: { setFAQY: React.Dispatch<React.SetStateAction<number>> }) => {
  const [toggle, setToggle] = useState<caseFAQ>({
    case1: false,
    case2: false,
    case3: false,
    case4: false,
    case5: false,
    case6: false,
  });
  const handleToggle = useCallback(
    (obj) => {
      setToggle({ ...toggle, ...obj });
    },
    [toggle]
  );

  return (
    <View
      style={stylesByPlatform({ webStyle: sharedStyles.rowBetween })}
      onLayout={(event) => setFAQY(event.nativeEvent.layout.y)}
    >
      <View style={stylesByPlatform({ mobileStyle: styles.block3Mobile, webStyle: styles.block3 })}>
        <Text style={styles.title2}>Frequently Asked Questions (FAQs)n</Text>

        <Spacer height={24} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case1: !toggle.case1 })}
          >
            {!toggle.case1 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>
              Is Genivisor a substitute for professional legal advice?
            </Text>
          </TouchableOpacity>

          {toggle.case1 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                Genivisor is designed to provide general legal information and guidance, but it is
                not a replacement for professional legal counsel. For complex legal matters, we
                recommend consulting a qualified attorney who can provide tailored advice based on
                your specific circumstances.
              </Text>
            </>
          ) : null}
        </View>

        <Spacer height={16} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case2: !toggle.case2 })}
          >
            {!toggle.case2 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>Can Genivisor help with international legal matters?</Text>
          </TouchableOpacity>

          {toggle.case2 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                While Genivisor offers a wide range of legal knowledge, its expertise may vary based
                on the jurisdiction. We recommend consulting a local legal professional for matters
                that involve international or specific regional legal complexities.
              </Text>
            </>
          ) : null}
        </View>

        <Spacer height={16} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case3: !toggle.case3 })}
          >
            {!toggle.case3 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>Is Genivisor accessible on all devices?</Text>
          </TouchableOpacity>

          {toggle.case3 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                Yes, Genivisor is designed to be accessible on various devices, including desktops,
                laptops, tablets, and mobile phones. You can conveniently access our platform from
                anywhere with an internet connection.
              </Text>
            </>
          ) : null}
        </View>

        <Spacer height={16} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case4: !toggle.case4 })}
          >
            {!toggle.case4 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>How reliable are the answers provided by Genivisor?</Text>
          </TouchableOpacity>

          {toggle.case4 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                Genivisor's AI is trained on a diverse dataset of legal information to provide
                accurate and reliable answers. However, the information provided is for
                informational purposes only and should not be considered a substitute for
                professional legal advice.
              </Text>
            </>
          ) : null}
        </View>

        <Spacer height={16} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case5: !toggle.case5 })}
          >
            {!toggle.case5 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>How secure is the information I provide to Genivisor?</Text>
          </TouchableOpacity>

          {toggle.case5 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                We prioritize the security and confidentiality of our users' data. Genivisor employs
                robust security measures to protect your information and ensure it remains private
                and secure.
              </Text>
            </>
          ) : null}
        </View>

        <Spacer height={16} />

        <View style={styles.faq}>
          <TouchableOpacity
            style={sharedStyles.rowAlignCenter}
            onPress={() => handleToggle({ case6: !toggle.case6 })}
          >
            {!toggle.case6 ? (
              <Image source={plusIcon} style={styles.icon4} />
            ) : (
              <Image source={minusIcon} style={styles.icon4} />
            )}

            <Spacer width={12} />

            <Text style={styles.title4}>Can Genivisor provide real-time legal updates?</Text>
          </TouchableOpacity>

          {toggle.case6 ? (
            <>
              <Spacer height={16} />

              <Text style={styles.description}>
                Genivisor is equipped to provide general legal information based on the data it has
                been trained on. For the latest legal updates and developments, we recommend staying
                informed through reputable legal resources and news outlets.
              </Text>
            </>
          ) : null}
        </View>
      </View>

      {isWebMobile ? <Spacer height={24} /> : null}

      <Image
        source={frame4Icon}
        style={stylesByPlatform({
          mobileStyle: styles.frame4IconMobile,
          webStyle: styles.frame4Icon,
        })}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  frame4Icon: { width: 572, height: 638 },
  frame4IconMobile: { width: screenWidth - 32, height: 397 },
  block3: { width: 628 },
  block3Mobile: { flex: 1 },
  description: { color: colors.black, fontSize: 16, fontWeight: '400', lineHeight: 24 },
  title2: { color: colors.black, fontSize: 32, fontWeight: '700' },
  title4: { color: colors.black, fontSize: 18, fontWeight: '600' },
  icon4: { width: 24, height: 24 },
  faq: {
    borderRadius: radii.input,
    padding: 16,
    backgroundColor: colors.white2,
    borderColor: colors.purple3,
    borderWidth: 1,
  },
});

export default FAQ;
