import React, { FC, useCallback, useMemo, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import { TextInput } from 'react-native-paper';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import { useChats } from '@context/Chats';
import colors from '@core/colors/colors';
import { isWebMobile } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { ChatInterface } from '@core/types';
import { stylesByPlatform, truncateChatName } from '@core/utils';

import EmptyData from './components/EmptyData';
import EmptyFilter from './components/EmptyFilter';
import ListItem from './components/ListItem';
import ListItemWeb from './components/ListItemWeb';

const searchIcon = require('@assets/search.png');

interface HistoryProps extends LoggedInStackScreenProps<'History'> {}

const History: FC<HistoryProps> = ({ navigation }) => {
  const { chats, handleDeleteChat } = useChats();
  const { t } = useLocalize();
  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = useCallback((text: string) => {
    setSearch(text);
  }, []);

  const data = useMemo(() => {
    return chats
      .filter((chat: ChatInterface) => chat.messages?.length > 0)
      .map((chat: ChatInterface) => {
        return {
          id: chat.id,
          name: truncateChatName(chat.messages?.[0]?.text, t('home.title.newChat')),
          date: chat.date,
        };
      });
  }, [chats, t]);

  const returnSortData = useMemo(() => {
    return data
      .sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (b.date > a.date) {
          return -1;
        }
        return 0;
      })
      .filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, data]);

  const returnEmptyScreen = useMemo(() => {
    if (search !== '' && data.length !== 0) {
      return <EmptyFilter />;
    }
    return <EmptyData />;
  }, [search, data]);

  const onPressChat = useCallback(
    (id: string) => {
      navigation.navigate('Home', { chatId: id });
    },
    [navigation]
  );

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header title={t('history.title.history')} navigation={navigation} />

      <View
        style={stylesByPlatform({ webStyle: sharedStyles.center, mobileStyle: sharedStyles.flex })}
      >
        <View
          style={[
            stylesByPlatform({
              webStyle: styles.centerWebData,
              mobileStyle: sharedStyles.paddingHorizontal16,
            }),
            sharedStyles.flex,
          ]}
        >
          <FlatList
            ListHeaderComponentStyle={styles.header}
            ListHeaderComponent={
              <TextInput
                mode="outlined"
                placeholder={t('history.input.search')}
                value={search}
                onChangeText={handleChangeSearch}
                activeOutlineColor={colors.purple2}
                placeholderTextColor={colors.gray2}
                style={styles.input}
                outlineStyle={styles.borderRadius}
                left={<TextInput.Icon icon={searchIcon} />}
              />
            }
            data={returnSortData}
            ListEmptyComponent={returnEmptyScreen}
            renderItem={({ item, index }) =>
              isWebMobile ? (
                <ListItem
                  item={item}
                  index={index}
                  arr={data}
                  onPressItem={onPressChat}
                  onDeleteItem={handleDeleteChat}
                />
              ) : (
                <ListItemWeb
                  item={item}
                  index={index}
                  arr={data}
                  onPressItem={onPressChat}
                  onDeleteItem={handleDeleteChat}
                />
              )
            }
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => <Spacer width={8} height={8} />}
          />

          <Spacer height={16} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: { paddingTop: 16, paddingBottom: 16 },
  input: {
    backgroundColor: colors.white,
    height: 40,
  },
  borderRadius: { borderRadius: radii.button },
  centerWebData: { width: 650 },
});

export default History;
