import React, { FC, useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

const activeLikeIcon = require('@assets/active-like.png');
const likeIcon = require('@assets/like.png');

interface LikeProps {
  onPress: () => void;
  isActive: boolean;
}
const Like: FC<LikeProps> = ({ onPress, isActive }) => {
  const offsetScale = useSharedValue(1);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: offsetScale.value }],
    };
  });

  const handlePressAndAnimation = useCallback(() => {
    offsetScale.value = withSpring(offsetScale.value * 1.2);
    onPress();
    setTimeout(() => {
      offsetScale.value = withSpring(1);
    }, 300);
  }, [offsetScale, onPress]);

  return (
    <TouchableOpacity onPress={handlePressAndAnimation}>
      <Animated.Image
        source={isActive ? activeLikeIcon : likeIcon}
        style={[styles.icon, animatedStyles]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: { width: 44, height: 44 },
});

export default Like;
