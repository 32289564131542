import React, { FunctionComponent } from "react";
import { SafeAreaView, View } from 'react-native';

import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import DrawerContent from '@components/DrawerContent';
import colors from '@core/colors/colors';
import { sharedStyles } from '@core/styles/sharedStyles';

import { LoggedInStackParamList, RootStackScreenProps } from './navigation';
import { commonDrawerOptions, commonDrawerOptionsWeb, commonNavigationOptions } from './options';
import DeepLinkLoading from '../screens/DeepLinkLoading';
import History from '../screens/History';
import Home from '../screens/home';
import Settings from '../screens/Settings';
import DeleteAccount from '../screens/Settings/DeleteAccount';
import Language from '../screens/Settings/Language';
import Subscription from '../screens/Settings/Subscription';
import { isWebMobile } from '@core/constants';

const LoggedInStack = createDrawerNavigator<LoggedInStackParamList>();
const SettingsStack = createNativeStackNavigator<LoggedInStackParamList>();

const extraSafeAreaStyles = {
  hided: { flex: 0, backgroundColor: colors.purple4 },
  main: { flex: 1, backgroundColor: colors.white },
};

const SettingsStackNavigation: FunctionComponent = () => {
  return (
    <SettingsStack.Navigator screenOptions={commonNavigationOptions}>
      <SettingsStack.Screen name="Settings" component={Settings} />

      <SettingsStack.Screen name="Language" component={Language} />

      <SettingsStack.Screen name="Subscription" component={Subscription} />

      <SettingsStack.Screen name="DeleteAccount" component={DeleteAccount} />
    </SettingsStack.Navigator>
  );
};

const LoggedInStackNavigation: FunctionComponent<RootStackScreenProps<'LoggedInStack'>> = () => {

  return (
    <View style={sharedStyles.flex}>
      <SafeAreaView style={extraSafeAreaStyles.hided} />

      <SafeAreaView style={extraSafeAreaStyles.main}>
        <LoggedInStack.Navigator
          screenOptions={isWebMobile ? commonDrawerOptions : commonDrawerOptionsWeb}
          drawerContent={({ navigation, state }: DrawerContentComponentProps) => {
            return <DrawerContent navigation={navigation} state={state} />;
          }}
        >
          <LoggedInStack.Screen name="Home" component={Home} />

          <LoggedInStack.Screen name="SettingsStack" component={SettingsStackNavigation} />

          <LoggedInStack.Screen name="History" component={History} />

          <LoggedInStack.Screen name="DeepLinkLoading" component={DeepLinkLoading} />
        </LoggedInStack.Navigator>
      </SafeAreaView>
    </View>
  );
};

export default LoggedInStackNavigation;
