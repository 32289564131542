/**
 * @format
 */

import React from 'react';
import { AppRegistry } from 'react-native';

import { registerRootComponent } from 'expo';
import { MD3LightTheme as DefaultTheme, PaperProvider } from 'react-native-paper';

import colors from '@core/colors/colors';
import { isWeb } from '@core/constants';

import App from './App';
import { name as appName } from './app.json';

const theme = {
  ...DefaultTheme,
  myOwnProperty: true,
  colors: {
    ...DefaultTheme.colors,
    ...colors,
  },
};

const ProviderApp = () => {
  return (
    <PaperProvider theme={theme}>
      <App />
    </PaperProvider>
  );
};
if (isWeb) {
  registerRootComponent(ProviderApp);
}
AppRegistry.registerComponent(appName, () => ProviderApp);
