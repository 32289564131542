import React, { FC } from 'react';
import { View } from 'react-native';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';

interface SubscriptionProps extends LoggedInStackScreenProps<'Subscription'> {}

const Subscription: FC<SubscriptionProps> = ({ navigation }) => {
  const { t } = useLocalize();

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('settings.button.subscription')}
        isBurgerMenu={false}
        isBack={true}
        navigation={navigation}
      />
    </View>
  );
};

export default Subscription;
