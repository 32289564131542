import React, { FC, useCallback, useEffect } from 'react';
import { Linking, View } from 'react-native';

import { ActivityIndicator } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import colors from '@core/colors/colors';
import { useAmplify } from '@core/hooks/Amplify';
import { sharedStyles } from '@core/styles/sharedStyles';

interface DeepLinkLoadingProps extends LoggedOutStackScreenProps<'DeepLinkLoading'> {}

const DeepLinkLoading: FC<DeepLinkLoadingProps> = ({ navigation }) => {
  const { listenToAutoSignInEvent } = useAmplify();

  const callBackSignIn = useCallback(() => {
    navigation.navigate('LoggedInStack');
  }, []);

  const callBackSignOut = useCallback(() => {
    navigation.goBack();
  }, []);

  const handleLink = useCallback(async (event) => {
    await listenToAutoSignInEvent({ callBackSignIn, callBackSignOut });
  }, []);

  useEffect(() => {
    Linking.addEventListener('url', handleLink);
  }, []);

  return (
    <View style={[sharedStyles.center, sharedStyles.backgroundColor, sharedStyles.flex]}>
      <ActivityIndicator color={colors.purple} size={30} />
    </View>
  );
};

export default DeepLinkLoading;
