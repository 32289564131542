import React, { FC } from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

const activeFeedbackIcon = require('@assets/active-feedback.png');
const feedbackIcon = require('@assets/feedback.png');

interface FeedbackButtonProps {
  onPress: () => void;
  isActive: boolean;
}

const FeedbackButton: FC<FeedbackButtonProps> = ({ onPress, isActive }) => {
  const offsetBlow = useSharedValue(1);
  const offsetOpacity = useSharedValue(1);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: offsetBlow.value }],
      opacity: withTiming(offsetOpacity.value, {
        duration: 300,
      }),
    };
  });

  const handlePress = () => {
    onPress();
    offsetBlow.value = withSpring(offsetBlow.value * 2);
    setTimeout(() => {
      offsetBlow.value = withSpring(1);
    }, 300);
  };

  return (
    <TouchableOpacity onPress={handlePress} style={styles.containerIcon}>
      <Animated.Image
        source={isActive ? activeFeedbackIcon : feedbackIcon}
        style={[styles.icon, styles.backIcon, animatedStyles]}
      />

      <Image source={isActive ? activeFeedbackIcon : feedbackIcon} style={styles.icon} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  containerIcon: { position: 'relative' },
  backIcon: { position: 'absolute' },
  icon: { width: 44, height: 44 },
});

export default FeedbackButton;
