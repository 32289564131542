import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Text } from 'react-native-paper';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';

const emptyIcon = require('@assets/empty-filter.png');

const EmptyFilter = () => {
  const { t } = useLocalize();
  return (
    <View style={sharedStyles.center}>
      <Spacer height={32} />

      <Image source={emptyIcon} style={styles.icon} />

      <Spacer height={24} />

      <Text style={styles.text}>{t('history.empty.title')}</Text>

      <Spacer height={24} />

      <Text variant={'titleMedium'} style={styles.desc}>
        {t('history.empty.description')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: { width: 64, height: 64 },
  text: { fontSize: 18, fontWeight: '700', textAlign: 'center', color: colors.black },
  desc: { textAlign: 'center', color: colors.black2, fontWeight: '400' },
});

export default EmptyFilter;
