import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Text } from 'react-native-paper';

import Spacer from '@components/Spacer';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';

const emptyIcon = require('@assets/empty-data.png');

const EmptyData = () => {
  const { t } = useLocalize();
  return (
    <View style={sharedStyles.center}>
      <Spacer height={32} />

      <Image source={emptyIcon} style={styles.icon} />

      <Spacer height={24} />

      <Text style={styles.text}>{t('history.empty.title2')}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: { width: 64, height: 64 },
  text: { fontSize: 18, fontWeight: '700', textAlign: 'center' },
});

export default EmptyData;
