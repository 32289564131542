import React from 'react';

import { Amplify } from 'aws-amplify';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import ChatProvider from '@context/Chats';
import LocaleProvider from '@context/Locale';
import UserProvider from '@context/User';
import { isMobile } from '@core/constants';
import { sharedStyles } from '@core/styles/sharedStyles';

import awsconfig from './src/aws-exports';
import RootNavigation from './src/navigation';

const newConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isMobile
      ? awsconfig.oauth.redirectSignIn
      : __DEV__
      ? 'http://localhost:19006/LoggedOutStack/DeepLinkLoading'
      : 'https://genivisor-app.reports.geniusee.space/LoggedOutStack/DeepLinkLoading',
    redirectSignOut: isMobile
      ? awsconfig.oauth.redirectSignOut
      : __DEV__
      ? 'http://localhost:19006/LoggedOutStack/DeepLinkLoading'
      : 'https://genivisor-app.reports.geniusee.space/LoggedOutStack/DeepLinkLoading',
  },
};

Amplify.configure(newConfig);

function App(): JSX.Element {
  return (
    <LocaleProvider>
      <UserProvider>
        <ChatProvider>
          <GestureHandlerRootView style={sharedStyles.flex}>
            <SafeAreaProvider>
              <RootNavigation />
            </SafeAreaProvider>
          </GestureHandlerRootView>
        </ChatProvider>
      </UserProvider>
    </LocaleProvider>
  );
}

export default App;
