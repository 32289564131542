import React, { FC, useCallback } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Avatar, Text } from 'react-native-paper';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import { useUser } from '@context/User';
import colors from '@core/colors/colors';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

interface SettingsProps extends LoggedInStackScreenProps<'Settings'> {}

const chevronRightIcon = require('@assets/chevron-right.png');
const languageIcon = require('@assets/language.png');
const logoutIcon = require('@assets/logout.png');
const noAccountIcon = require('@assets/no-account.png');
const subscriptionsIcon = require('@assets/subscriptions.png');
const userEmpty = require('@assets/user.png');

const Settings: FC<SettingsProps> = ({ navigation }) => {
  const { user } = useUser();
  const { t } = useLocalize();
  const { signOut } = useAmplify();
  const name = 'Some Name';

  const handleNavigateTo = useCallback(
    (link: string) => {
      switch (link) {
        case 'Language':
          navigation.navigate('Language');
          break;
        case 'Subscription':
          navigation.navigate('Subscription');
          break;
        case 'DeleteAccount':
          navigation.navigate('DeleteAccount');
          break;
      }
    },
    [navigation]
  );

  const handleLogOut = useCallback(async () => {
    try {
      await signOut();
      navigation.replace('LoggedOutStack');
    } catch (error) {
      console.log(error);
    }
  }, [navigation, signOut]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header title={t('settings.title.settings')} navigation={navigation} />

      <View
        style={stylesByPlatform({ webStyle: sharedStyles.center, mobileStyle: sharedStyles.flex })}
      >
        <View
          style={[
            stylesByPlatform({
              webStyle: styles.centerWeb,
              mobileStyle: sharedStyles.padding16,
            }),
            sharedStyles.flex,
          ]}
        >
          <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
            <Avatar.Image
              size={50}
              source={user?.attributes?.picture ? { uri: user.attributes.picture } : userEmpty}
            />

            <Spacer width={8} />

            <Text style={styles.nameText}>{name.toUpperCase()}</Text>
          </View>

          <Spacer height={16} />

          <TouchableOpacity
            onPress={() => handleNavigateTo('Language')}
            style={[
              sharedStyles.rowBetween,
              sharedStyles.paddingHorizontal16,
              styles.button,
              sharedStyles.rowAlignCenter,
            ]}
          >
            <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
              <Image source={languageIcon} style={styles.icon} />

              <Spacer width={12} />

              <Text variant={'bodyLarge'} style={{ color: colors.black }}>
                {t('settings.button.language')}
              </Text>
            </View>

            <Image source={chevronRightIcon} style={styles.icon} />
          </TouchableOpacity>

          {/* <Spacer height={8} />

        <TouchableOpacity
          onPress={() => handleNavigateTo('Subscription')}
          style={[
            sharedStyles.rowBetween,
            sharedStyles.paddingHorizontal16,
            styles.button,
            sharedStyles.rowAlignCenter,
          ]}
        >
          <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
            <Image source={subscriptionsIcon} style={styles.icon} />

            <Spacer width={12} />

            <Text variant={'bodyLarge'} style={{ color: colors.black }}>
              {t('settings.button.subscription')}
            </Text>
          </View>

          <Image source={chevronRightIcon} style={styles.icon} />
        </TouchableOpacity> */}

          <Spacer height={8} />

          <TouchableOpacity
            onPress={() => handleNavigateTo('DeleteAccount')}
            style={[
              sharedStyles.rowBetween,
              sharedStyles.paddingHorizontal16,
              styles.button,
              sharedStyles.rowAlignCenter,
            ]}
          >
            <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
              <Image source={noAccountIcon} style={styles.icon} />

              <Spacer width={12} />

              <Text variant={'bodyLarge'} style={{ color: colors.black }}>
                {t('settings.button.deleteAccount')}
              </Text>
            </View>

            <Image source={chevronRightIcon} style={styles.icon} />
          </TouchableOpacity>

          <Spacer height={8} />

          <TouchableOpacity
            onPress={() => handleLogOut()}
            style={[sharedStyles.rowBetween, styles.button]}
          >
            <View style={[sharedStyles.paddingHorizontal16, sharedStyles.rowAlignCenter]}>
              <Image source={logoutIcon} style={styles.icon} />

              <Spacer width={12} />

              <Text variant={'bodyLarge'} style={{ color: colors.red }}>
                {t('settings.button.logOut')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  nameText: { color: colors.purple5, fontSize: 14, fontWeight: '600' },
  button: { backgroundColor: colors.purple4, height: 56, borderRadius: radii.button },
  icon: { width: 24, height: 24 },
  centerWeb: { width: 650, paddingTop: 16 },
});

export default Settings;
