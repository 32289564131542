import fetchMessages from "@api/fetchMessages";
import { useUser } from "@context/User";
import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { FlatList as FlatListMobile, KeyboardAvoidingView, StyleSheet, View } from 'react-native';

import { FlatList as FlatListWeb } from 'react-native-web';

import { LoggedInStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import { useChats } from '@context/Chats';
import { isIos, isWeb } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';
import { ChatInterface, MessageInterface, SendMessage } from '@core/types';
import { stylesByPlatform, truncateChatName } from '@core/utils';

import EmptyChat from './components/EmptyChat';
import InputMessage from './components/InputMessage';
import Message from './components/Message';

interface HomeProps extends LoggedInStackScreenProps<'Home'> {}

const FlatList = isWeb ? FlatListWeb : FlatListMobile;

const Home: FC<HomeProps> = ({ navigation, route }) => {
  const { chats, handleSetMessage, handleCreateChat, handleDeleteMessage, setMessages, loading } =
    useChats();
  const [currentChatId, setCurrentChatId] = useState<string>('');
  const { user } = useUser();
  const flatListRef = React.useRef<FlatListMobile>();
  const { t } = useLocalize();

  useEffect(() => {
    if (route?.params?.chatId) {
      setCurrentChatId((prevState: string) =>
        prevState !== route?.params?.chatId ? route?.params?.chatId || '' : prevState
      );

      if (user && user.username && !loading) {
        fetchMessages(route?.params?.chatId, user.token).then((messages) => {
          if (messages) {
            setMessages(messages, route.params.chatId ?? '');
          }
        });
      }
    } else {
      const chatId = handleCreateChat();
      setCurrentChatId(chatId);
      navigation.setParams({ chatId });
    }
  }, [handleCreateChat, navigation, route?.params?.chatId, user, loading]);

  const deleteLastMessage = useCallback(() => {
    const chat = chats.find((chat: ChatInterface) => chat.id === currentChatId);
    if (chat?.messages?.length) {
      const lastMessageId = chat.messages[chat.messages.length - 1].id;
      handleDeleteMessage({ chatId: currentChatId, messageId: lastMessageId });
    }
  }, [chats, currentChatId, handleDeleteMessage]);

  const sendMessage = useCallback(
    ({
      senderId,
      text,
      id,
      like,
      dislike,
      feedback,
      scrollToEnd,
      error,
      nonAnsweredQuestion,
      userId,
    }: SendMessage) => {
      const newChatMessage = {
        id: id,
        text: text,
        like: !!like,
        dislike: !!dislike,
        sender: {
          id: senderId,
          avatar: '',
          firstName: '',
          lastName: '',
        },
        date: new Date().valueOf(),
        feedback: feedback || '',
        error: !!error,
        errorMsg: '',
        showAskExternalBtn: !!nonAnsweredQuestion,
        userId: userId || '',
        currentChatId: currentChatId,
      };
      handleSetMessage({ chatId: currentChatId, message: newChatMessage });
      if (scrollToEnd) {
        setTimeout(() => {
          flatListRef?.current?.scrollToEnd();
        }, 300);
      }
    },
    [currentChatId, handleSetMessage]
  );

  const messages = useMemo(
    () => chats.find((chat: ChatInterface) => chat.id === currentChatId)?.messages,
    [chats, currentChatId]
  );

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={52}
      behavior={isIos ? 'padding' : 'height'}
      style={[sharedStyles.backgroundColor, sharedStyles.flex]}
    >
      <Header
        title={truncateChatName(messages?.[0]?.text, t('home.title.newChat'))}
        navigation={navigation}
      />

      <View
        style={[
          sharedStyles.spaceBetween,
          sharedStyles.flex,
          stylesByPlatform({ webStyle: sharedStyles.center }),
        ]}
      >
        <FlatList
          ref={flatListRef}
          data={messages}
          style={[
            stylesByPlatform({
              webStyle: styles.centerWeb,
              mobileStyle: sharedStyles.paddingHorizontal16,
            }),
            messages?.length && stylesByPlatform({ webStyle: styles.centerWebData }),
          ]}
          renderItem={({ item }: { item: MessageInterface }) => (
            <Message
              message={item}
              sendMessage={sendMessage}
              deleteLastMessage={deleteLastMessage}
              lastUserQuestion={
                messages
                  ? messages.filter((message) => message.sender.id === 'user').pop()!.text
                  : ''
              }
            />
          )}
          ListEmptyComponent={() => <EmptyChat />}
          ItemSeparatorComponent={() => <Spacer width={16} height={16} />}
          keyExtractor={(item: MessageInterface) => item.id}
          ListHeaderComponent={() => <Spacer width={16} height={16} />}
          ListFooterComponent={() => <Spacer width={16} height={16} />}
        />

        <InputMessage sendMessage={sendMessage} currentChatId={currentChatId} />
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  centerWebData: { width: 650 },
});

export default Home;
