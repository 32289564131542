import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { useUser } from '@context/User';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

type SignUpParameters = {
  username: string;
  password: string;
  email: string;
};

type SignInParameters = {
  username: string;
  password: string;
};

export const useAmplify = () => {
  const { handleSetUser } = useUser();
  const signUp = async (
    { username, password, email }: SignUpParameters,
    onSuccess: (response: ISignUpResult) => void,
    onError: (error: Error) => void,
    onFinally?: () => void
  ) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      })
        .then(onSuccess)
        .catch(onError)
        .finally(onFinally);
    } catch (error) {
      onError(new Error('Error signing up'));
    }
  };

  const listenToAutoSignInEvent = async ({
    callBackSignIn,
    callBackSignOut,
  }: {
    callBackSignIn: () => void;
    callBackSignOut: () => void;
  }) => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      console.log(data, 'data');
      if (data) {
        callBackSignIn();
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        data['token'] = token;
        handleSetUser(data);
      } else {
        callBackSignOut();
        handleSetUser(null);
      }
    } catch (error) {
      callBackSignOut();
      handleSetUser(null);
    }
  };

  const signIn = async ({ username, password }: SignInParameters) => {
    try {
      return await Auth.signIn(username, password);
    } catch (err) {
      const error = err as Error;
      throw new Error(error.message);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const signInGoogle = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      return true;
    } catch (error) {
      console.log('error signing in', error);
      return false;
    }
  };

  const signInApple = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Apple,
      });
      return true;
    } catch (error) {
      console.log('error signing in', error);
      return false;
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      const data = await Auth.forgotPassword(username);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const forgotPasswordSubmit = async (username: string, code: string, newPassword: string) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const verifyEmail = async (email: string, code: string) => {
    try {
      return await Auth.confirmSignUp(email, code);
    } catch (err) {
      const error = err as Error;
      throw new Error(error.message);
    }
  };

  const resendRequest = async (username: string) => {
    try {
      return await Auth.resendSignUp(username); // Optionally return result for further processing
    } catch (error) {
      throw new Error('Error resending verification code');
    }
  };

  const deleteAccount = async () => {
    try {
      await Auth.deleteUser();
    } catch (err) {
      const error = err as Error;
      throw new Error(error.message);
    }
  };

  return {
    signUp,
    signIn,
    signOut,
    signInGoogle,
    signInApple,
    listenToAutoSignInEvent,
    forgotPassword,
    forgotPasswordSubmit,
    verifyEmail,
    resendRequest,
    deleteAccount,
  };
};
