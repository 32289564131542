/* eslint-disable react-native/no-raw-text */
import React, { FC, SetStateAction, useCallback } from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Button, Text } from 'react-native-paper';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isWebMobile, screenHeight, screenWidth } from '@core/constants';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';

const menuIcon = require('@assets/burger-menu.png');
const logoIcon = require('@assets/logo.png');

interface HeaderProps {
  scrollViewRef: React.RefObject<ScrollView>;
  AboutY: number;
  FAQY: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

const Header: FC<HeaderProps> = ({ scrollViewRef, AboutY, FAQY, isOpen, setIsOpen }) => {
  const navigation = useNavigation();
  const offsetTranslateY = useSharedValue(-screenHeight);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: offsetTranslateY.value }],
    };
  });

  const openMenu = useCallback(() => {
    offsetTranslateY.value = withTiming(88, {
      duration: 300,
      easing: Easing.linear,
    });
    setIsOpen(true);
  }, [offsetTranslateY, setIsOpen]);

  const closeMenu = useCallback(() => {
    offsetTranslateY.value = withTiming(-screenHeight, {
      duration: 300,
      easing: Easing.linear,
    });
    setIsOpen(false);
  }, [offsetTranslateY, setIsOpen]);

  const handleAbout = useCallback(() => {
    if (isWebMobile) {
      closeMenu();
    }
    setTimeout(() => {
      if (scrollViewRef?.current) {
        scrollViewRef.current.scrollTo({ x: 0, y: AboutY, animated: true });
      }
    }, 1000);
  }, [AboutY, closeMenu, scrollViewRef]);

  const handleFAQ = useCallback(() => {
    if (isWebMobile) {
      closeMenu();
    }
    setTimeout(() => {
      if (scrollViewRef?.current) {
        scrollViewRef.current.scrollTo({ x: 0, y: FAQY, animated: true });
      }
    }, 1000);
  }, [FAQY, closeMenu, scrollViewRef]);

  const handleContact = useCallback(() => {
    if (isWebMobile) {
      closeMenu();
    }
    Linking.openURL('mailto:example@example.com');
  }, [closeMenu]);

  const handleJoin = useCallback(() => {
    if (isWebMobile) {
      closeMenu();
    }
    navigation.navigate('LoggedOutStack');
  }, [closeMenu, navigation]);

  const handleOpenMenu = useCallback(() => {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }, [closeMenu, isOpen, openMenu]);

  return isWebMobile ? (
    <View style={styles.headerMobile}>
      <View style={styles.headerMobile}>
        <Spacer height={28} />

        <View
          style={[
            sharedStyles.rowCenterBetween,
            styles.headerMobile,
            sharedStyles.paddingHorizontal16,
          ]}
        >
          <Image source={logoIcon} style={styles.logoIcon} />

          <TouchableOpacity
            onPress={handleOpenMenu}
            style={[styles.button, isOpen && styles.activeButton]}
          >
            <Image source={menuIcon} style={styles.icon} />
          </TouchableOpacity>
        </View>

        <Spacer height={28} />
      </View>

      {
        <TouchableWithoutFeedback onPress={closeMenu}>
          <Animated.View style={[styles.menuBackGround, animatedStyles]}>
            <View style={styles.menu}>
              <TouchableOpacity onPress={handleAbout}>
                <Text>About</Text>
              </TouchableOpacity>

              <Spacer height={12} />

              <TouchableOpacity onPress={handleFAQ}>
                <Text>FAQ</Text>
              </TouchableOpacity>

              <Spacer height={12} />

              <TouchableOpacity onPress={handleContact}>
                <Text>Contact</Text>
              </TouchableOpacity>

              <Spacer height={12} />

              <Button
                onPress={handleJoin}
                mode={'outlined'}
                textColor={colors.black}
                buttonColor={colors.purple4}
                style={{ borderColor: colors.purple3 }}
              >
                Join for Free
              </Button>
            </View>
          </Animated.View>
        </TouchableWithoutFeedback>
      }
    </View>
  ) : (
    <View style={{ paddingHorizontal: 80, backgroundColor: colors.white }}>
      <Spacer height={28} />

      <View style={sharedStyles.rowCenterBetween}>
        <Image source={logoIcon} style={styles.logoIcon} />

        <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
          <TouchableOpacity onPress={handleAbout}>
            <Text>About</Text>
          </TouchableOpacity>

          <Spacer width={32} />

          <TouchableOpacity onPress={handleFAQ}>
            <Text>FAQ</Text>
          </TouchableOpacity>

          <Spacer width={32} />

          <TouchableOpacity onPress={handleContact}>
            <Text>Contact</Text>
          </TouchableOpacity>

          <Spacer width={32} />

          <Button
            onPress={handleJoin}
            mode={'outlined'}
            textColor={colors.black}
            buttonColor={colors.purple4}
            style={{ borderColor: colors.purple3 }}
          >
            Join for Free
          </Button>
        </View>
      </View>

      <Spacer height={28} />
    </View>
  );
};

const styles = StyleSheet.create({
  logoIcon: { width: 108, height: 26 },
  icon: { width: 24, height: 24 },
  menuBackGround: {
    backgroundColor: 'rgba(39, 39, 61, 0.37)',
    width: screenWidth,
    height: screenHeight,
    position: 'absolute',
    zIndex: 1,
  },
  headerMobile: { position: 'relative', zIndex: 123, backgroundColor: colors.white },
  menu: {
    backgroundColor: colors.white,
    paddingVertical: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: { padding: 8, borderRadius: radii.button },
  activeButton: { backgroundColor: colors.purple6 },
});

export default Header;
