import { MMKV } from 'react-native-mmkv';

const storage = new MMKV();

const Storage = {
  set: (key: string, value: string) => {
    storage.set(key, value);
  },

  get: (key: string) => {
    return storage.getString(key);
  },

  getParsed: <T = string>(key: string) => {
    const value = storage.getString(key);
    if (value === undefined) {
      throw new Error(`Nothing found by key '${key}'`);
    }
    return JSON.parse(value) as T;
  },

  contains: (key: string) => {
    return storage.contains(key);
  },

  remove: (key: string) => {
    storage.delete(key);
  },

  removeAll: () => {
    storage.clearAll();
  },
};

export default Storage;
