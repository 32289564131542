import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

export const commonNavigationOptions = {
  headerShown: false,
};

export const commonDrawerOptions: DrawerNavigationOptions = {
  headerShown: false,
  drawerType: 'front',
  drawerStyle: {
    width: 80,
  },
  headerLeft: () => null,
  headerStyle: {
    height: 0,
  },
};

export const commonDrawerOptionsWeb: DrawerNavigationOptions = {
  headerShown: false,
  drawerType: 'front',
  drawerStyle: {
    width: 240,
  },
  headerLeft: () => null,
  headerStyle: {
    height: 0,
  },
};
