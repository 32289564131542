import React, { FC, useCallback, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Button, Text } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isMobile, screenWidth } from '@core/constants';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const CELL_COUNT = 6;

interface VerifyEmailProps extends LoggedOutStackScreenProps<'VerifyEmail'> {}

const VerifyEmail: FC<VerifyEmailProps> = ({ navigation, route }) => {
  const { t } = useLocalize();
  const { forgotPassword } = useAmplify();
  const [sec, setSec] = useState<number>(60);
  const [value, setValue] = useState<string>('');
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const minusSecond = useCallback(() => {
    setSec(sec - 1);
  }, [sec]);

  useEffect(() => {
    setTimeout(() => {
      if (sec !== 0) {
        minusSecond();
      }
    }, 1000);
  }, [minusSecond, sec]);

  const handleGoBack = useCallback(() => {
    navigation.navigate('LogIn');
  }, [navigation]);

  const handleGoToVerifyEmail = useCallback(() => {
    navigation.navigate('ResetPassword', { email: route.params.email, code: value });
  }, [navigation, route.params.email, value]);

  const handleRequestAgain = useCallback(async () => {
    setSec(60);
    try {
      await forgotPassword(route.params.email);
    } catch (error) {
      console.log(error);
    }
  }, [forgotPassword, route.params.email]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('forgetPassword.header.forgetPassword')}
        navigation={navigation}
        isBack
        isBurgerMenu={false}
      />

      <View style={stylesByPlatform({ webStyle: sharedStyles.center })}>
        <View style={[sharedStyles.padding16, stylesByPlatform({ webStyle: styles.centerWeb })]}>
          <Text variant={'bodyLarge'}>{t('forgetPassword.title.verify')}</Text>

          <Spacer height={16} />

          <CodeField
            value={value}
            ref={ref}
            {...props}
            onChangeText={setValue}
            cellCount={CELL_COUNT}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({ index, symbol, isFocused }) => (
              <Text
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}
              >
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            )}
          />

          <Spacer height={16} />

          {sec === 0 ? (
            <View style={[sharedStyles.row, sharedStyles.center]}>
              <Text variant={'bodyLarge'}>{t('forgetPassword.button.receiveCode')}</Text>

              <TouchableOpacity onPress={handleRequestAgain} style={sharedStyles.center}>
                <Text variant={'bodyLarge'} style={styles.textLogin}>
                  {` ${t('forgetPassword.button.requestAgain')}`}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <Text variant={'bodyLarge'} style={styles.centerText}>
              {t('forgetPassword.button.request')}

              <Text variant={'titleMedium'}>{` ${sec} ${t(
                'forgetPassword.button.notDeleteAccount'
              )}`}</Text>
            </Text>
          )}

          <Spacer height={16} />

          <Button
            mode="contained"
            onPress={handleGoToVerifyEmail}
            buttonColor={colors.black}
            style={styles.button}
          >
            <Text style={[{ color: colors.white }, styles.buttonText]}>
              {t('forgetPassword.button.resetPassword')}
            </Text>
          </Button>

          <Spacer height={22} />

          <TouchableOpacity onPress={handleGoBack} style={sharedStyles.center}>
            <Text variant={'titleMedium'} style={styles.textLogin}>
              {t('forgetPassword.button.goToLogIn')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 8 },
  textLogin: { color: colors.purple2, fontWeight: '600' },
  centerText: { textAlign: 'center' },
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: isMobile ? (screenWidth - 32 - 8 * 4) / CELL_COUNT : (500 - 32 - 8 * 4) / CELL_COUNT,
    height: 54,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 1,
    borderColor: colors.gray4,
    textAlign: 'center',
    borderRadius: radii.button,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
  },
  focusCell: {
    borderColor: colors.gray4,
  },
});

export default VerifyEmail;
