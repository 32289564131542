import React, { FC, useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Button, Text } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const congratulationsIcon = require('@assets/congratulations.png');

interface ResetPasswordSuccessProps extends LoggedOutStackScreenProps<'ResetPasswordSuccess'> {}

const ResetPasswordSuccess: FC<ResetPasswordSuccessProps> = ({ navigation }) => {
  const { t } = useLocalize();

  const handleGoToLogIn = useCallback(() => {
    navigation.navigate('LogIn');
  }, [navigation]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('forgetPassword.header.resetPassword')}
        navigation={navigation}
        isBack
        isBurgerMenu={false}
      />

      <View style={stylesByPlatform({ webStyle: sharedStyles.center })}>
        <View
          style={[
            sharedStyles.padding16,
            sharedStyles.center,
            stylesByPlatform({ webStyle: styles.centerWeb }),
          ]}
        >
          <Spacer height={32} />

          <Image source={congratulationsIcon} style={styles.icon} />

          <Spacer height={16} />

          <Text variant={'titleLarge'} style={styles.title}>
            {t('forgetPassword.title.ResetPasswordSuccess')}
          </Text>

          <Spacer height={16} />

          <Text variant={'bodyLarge'}>{t('forgetPassword.description.resetPasswordSuccess')}</Text>

          <Spacer height={16} />

          <View style={styles.buttonContainer}>
            <Button
              mode="contained"
              onPress={handleGoToLogIn}
              buttonColor={colors.black}
              style={styles.button}
            >
              <Text style={[{ color: colors.white }, styles.buttonText]}>
                {t('forgetPassword.button.goToLogIn')}
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  buttonContainer: { width: '100%' },
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 8 },
  icon: { width: 64, height: 64 },
  title: { fontWeight: '700' },
});

export default ResetPasswordSuccess;
