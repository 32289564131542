import React, { FunctionComponent, useCallback, useEffect } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Hub } from 'aws-amplify';
import SplashScreen from 'react-native-splash-screen';

import { useLocale } from '@context/Locale';
import { isWeb } from '@core/constants';
import { useAmplify } from '@core/hooks/Amplify';

import LoggedInStackNavigation from './LoggedInStack';
import LoggedOutStackNavigation from './LoggedOutStack';
import { RootStackParamList } from './navigation';
import { commonNavigationOptions, navigationRef } from './options';
import LandingPage from '../screens/LandingPage';
import NotFoundScreen from '../screens/NotFoundScreen';

const RootStack = createNativeStackNavigator<RootStackParamList>();

const getInitialRouteName: () => 'LoggedInStack' | 'LoggedOutStack' | undefined = () => {
  return 'LoggedOutStack';
};

const config = {
  screens: {
    LoggedOutStack: {
      path: 'LoggedOutStack',
      screens: {
        Start: 'Start',
        LogIn: 'LogIn',
        SignUp: 'SignUp',
        ForgotPassword: 'ForgotPassword',
        VerifyEmail: 'VerifyEmail',
        ResetPassword: 'ResetPassword',
        ResetPasswordSuccess: 'ResetPasswordSuccess',
        VerifyEmailPage: 'VerifyEmailPage',
      },
    },
    LoggedInStack: {
      path: 'LoggedInStack',
      screens: {
        Home: 'Home',
        Settings: {
          path: 'Settings',
          screens: {
            Language: 'Language',
            Subscription: 'Subscription',
            DeleteAccount: 'DeleteAccount',
          },
        },
        History: 'History',
      },
    },
    LandingPage: '',
    DeepLinkLoading: 'DeepLinkLoading',
  },
};

const linking = {
  prefixes: ['http://localhost:19006/', 'localhost://'],
  config,
};

const RootNavigation: FunctionComponent = () => {
  const { init } = useLocale();
  const { listenToAutoSignInEvent } = useAmplify();

  useEffect(() => {
    init();
  }, []);

  const callBackSignIn = useCallback(() => {
    navigationRef.navigate('LoggedInStack');
  }, []);

  const callBackSignOut = useCallback(() => {
    navigationRef.navigate('LoggedOutStack');
  }, []);

  useEffect(() => {
    if (!isWeb) {
      SplashScreen.hide();
    }

    listenToAutoSignInEvent({ callBackSignIn, callBackSignOut }).catch(e => {
      console.log('Erorr: sign in erro')
    });
  }, []);

  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <RootStack.Navigator
        initialRouteName={getInitialRouteName()}
        screenOptions={commonNavigationOptions}
      >
        <RootStack.Screen name="LoggedOutStack" component={LoggedOutStackNavigation} />

        <RootStack.Screen name="LoggedInStack" component={LoggedInStackNavigation} />

        <RootStack.Screen name="NotFound" component={NotFoundScreen} />

        <RootStack.Screen name="LandingPage" component={LandingPage} />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export default RootNavigation;
