import React, { FC, useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Button, HelperText, Text } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Header from '@components/Header';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isMobile, screenWidth } from '@core/constants';
import { useAmplify } from '@core/hooks/Amplify';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const logoIcon = require('@assets/logo.png');

const CELL_COUNT = 6;

interface VerifyEmailPageProps extends LoggedOutStackScreenProps<'VerifyEmailPage'> {}

const VerifyEmailPage: FC<VerifyEmailPageProps> = ({ navigation, route }) => {
  const { t } = useLocalize();
  const { verifyEmail, resendRequest } = useAmplify();
  const [sec, setSec] = useState<number>(60);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isErrorTouched, setIsErrorTouched] = useState<boolean>(false);
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const minusSecond = useCallback(() => {
    setSec(sec - 1);
  }, [sec]);

  useEffect(() => {
    setTimeout(() => {
      if (sec !== 0) {
        minusSecond();
      }
    }, 1000);
  }, [minusSecond, sec]);

  const handleVerifyEmail = useCallback(async () => {
    setIsErrorTouched(true);
    if (value.length > 6) {
      setError(t('login.error.codeNotValid'));
      return;
    }

    if (!route.params.email) {
      navigation.replace('LogIn');
      return;
    }

    try {
      const result = await verifyEmail(route.params.email, value);
      if (result === 'SUCCESS') {
        navigation.replace('LogIn');
      }
    } catch (err) {
      const error = err as Error;

      if (error.message == 'User cannot be confirmed. Current status is CONFIRMED') {
        navigation.replace('LogIn');
        return;
      }

      if (error.message === 'Attempt limit exceeded, please try after some time.') {
        setError(t('login.error.limitedAttempt'));
        return;
      }

      if (error.message === 'Invalid verification code provided, please try again.') {
        setError(t('login.error.invalidVerificationCode'));
      }

      setError(t('login.error.wrong'));
    }
  }, [navigation, route.params.email, t, value, verifyEmail]);

  const handleRequestAgain = useCallback(async () => {
    await resendRequest(route.params.email).then(() => setSec(60));
  }, [resendRequest, route.params.email]);

  return (
    <View style={[sharedStyles.backgroundColor, sharedStyles.flex]}>
      <Header
        title={t('login.button.signup')}
        //this type error appear everywhere we use Header
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        //@ts-ignore
        navigation={navigation}
        isBurgerMenu={false}
        isBack
      />

      <View style={stylesByPlatform({ webStyle: sharedStyles.center })}>
        <Spacer height={100} />

        <Image source={logoIcon} style={styles.logo} />

        <Spacer height={22} />

        <Text variant="titleMedium" style={{ color: colors.black }}>
          Join Genivisor today!
        </Text>

        <View style={[sharedStyles.padding16, stylesByPlatform({ webStyle: styles.centerWeb })]}>
          <Text variant={'bodyLarge'}>{t('forgetPassword.title.verify')}</Text>

          <Spacer height={16} />

          <CodeField
            value={value}
            ref={ref}
            {...props}
            onChangeText={(text) => {
              setIsErrorTouched(false);
              setValue(text);
            }}
            cellCount={CELL_COUNT}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({ index, symbol, isFocused }) => (
              <Text
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}
              >
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            )}
          />

          {isErrorTouched && !(error.length > 0) && (
            <HelperText
              type="error"
              visible={isErrorTouched && !(error.length > 0)}
              style={styles.centerText}
            >
              {error}
            </HelperText>
          )}

          <Spacer height={16} />

          {sec === 0 ? (
            <View style={[sharedStyles.row, sharedStyles.center]}>
              <Text variant={'bodyLarge'}>{t('forgetPassword.button.receiveCode')}</Text>

              <TouchableOpacity onPress={handleRequestAgain} style={sharedStyles.center}>
                <Text variant={'bodyLarge'} style={styles.textLogin}>
                  {` ${t('forgetPassword.button.requestAgain')}`}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <Text variant={'bodyLarge'} style={styles.centerText}>
              {t('forgetPassword.button.request')}

              <Text variant={'titleMedium'}>{` ${sec} ${t(
                'forgetPassword.button.notDeleteAccount'
              )}`}</Text>
            </Text>
          )}

          <Spacer height={16} />

          <Button
            mode="contained"
            onPress={handleVerifyEmail}
            buttonColor={colors.black}
            style={styles.button}
          >
            <Text style={[{ color: colors.white }, styles.buttonText]}>
              {t('login.button.verifyEmail')}
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 500 },
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8, fontWeight: '600', paddingTop: 8 },
  textLogin: { color: colors.purple2, fontWeight: '600' },
  centerText: { textAlign: 'center' },
  logo: { width: 179, height: 42 },
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: isMobile ? (screenWidth - 32 - 8 * 4) / CELL_COUNT : (500 - 32 - 8 * 4) / CELL_COUNT,
    height: 54,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 1,
    borderColor: colors.gray4,
    textAlign: 'center',
    borderRadius: radii.button,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
  },
  focusCell: {
    borderColor: colors.gray4,
  },
});

export default VerifyEmailPage;
