/* eslint-disable react-native/no-raw-text */
import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import moment from 'moment';
import { Text } from 'react-native-paper';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const logoIcon = require('@assets/logo.png');

const Footer = () => {
  return (
    <View style={[styles.footer, sharedStyles.center]}>
      <Spacer height={24} />

      <Image source={logoIcon} style={styles.logoIcon} />

      <Spacer height={16} />

      <View style={stylesByPlatform({ webStyle: sharedStyles.row })}>
        <TouchableOpacity>
          <Text
            style={[stylesByPlatform({ mobileStyle: sharedStyles.textCenter }), styles.footerText]}
          >
            Privacy Policy
          </Text>
        </TouchableOpacity>

        <Spacer width={16} height={16} />

        <TouchableOpacity>
          <Text
            style={[stylesByPlatform({ mobileStyle: sharedStyles.textCenter }), styles.footerText]}
          >
            Terms & Conditions
          </Text>
        </TouchableOpacity>

        <Spacer width={16} height={16} />

        <TouchableOpacity>
          <Text
            style={[stylesByPlatform({ mobileStyle: sharedStyles.textCenter }), styles.footerText]}
          >
            Contact us
          </Text>
        </TouchableOpacity>
      </View>

      <Spacer height={16} />

      <Text
        style={[stylesByPlatform({ mobileStyle: sharedStyles.textCenter }), styles.footerText]}
      >{`© ${moment().format('YYYY')} Geniusee. All rights reserved.`}</Text>

      <Spacer height={16} />
    </View>
  );
};

const styles = StyleSheet.create({
  logoIcon: { width: 108, height: 26 },
  footer: {
    backgroundColor: 'rgba(243, 243, 255, 0.2)',
    borderTopColor: colors.purple3,
    borderTopWidth: 1,
  },
  footerText: { color: colors.purple5, fontSize: 13, fontWeight: '400' },
});

export default Footer;
