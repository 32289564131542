import { Dimensions, Platform } from 'react-native';
const screenSize = Dimensions.get('window');

export const screenWidth = screenSize.width;
export const screenHeight = screenSize.height;

export const BREAKPOINTS = {
  smallPhoneWidth: 320,
  smallPhoneHeight: 600,
  mediumPhoneWidth: 414,
  bigPhoneWidth: 480,
  xsHeight: 667,
};

export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';
export const isMobile = isIos || isAndroid;
export const isWebMobile =
  (Platform.OS === 'web' && screenWidth <= BREAKPOINTS.bigPhoneWidth) || isMobile;

export const isBigScreen = screenWidth >= BREAKPOINTS.mediumPhoneWidth;
export const isBiggestPhoneScreen = screenWidth >= BREAKPOINTS.bigPhoneWidth;
export const isHeightMoreXS = screenHeight > BREAKPOINTS.xsHeight;

export const ONE_MINUTE = 1000 * 60;
export const FIFTEEN_MINUTES = ONE_MINUTE * 15;
export const HALF_HOUR = ONE_MINUTE * 30;
export const HOUR = ONE_MINUTE * 60;
export const MAX_PHONE_LENGTH = 10;
