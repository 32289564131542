import { createIntl, createIntlCache } from 'react-intl';
import type { IntlConfig, IntlShape } from 'react-intl';
import { getLocales } from 'react-native-localize';

import localeEn from '@core/locales/map/en.json';
import localeUa from '@core/locales/map/ua.json';

export type Locale = keyof typeof translations;

type FormatMessageParams = Parameters<IntlShape['formatMessage']>;

export enum Locales {
  en = 'en',
  ua = 'ua',
}

const intlCache = createIntlCache();
export let globalIntl: IntlShape | undefined;

export const translations = {
  en: localeEn,
  ua: localeUa,
};

export const createGlobalIntl = (config: IntlConfig): IntlShape => {
  globalIntl = createIntl(config, intlCache);

  return globalIntl;
};

export const getSupportedLocalesMap = <T extends Record<Locale, Locale>>(): T => {
  const supportedLocales = Object.keys(translations) as Array<Locale>;

  return supportedLocales.reduce<T>((accumulator, locale) => {
    accumulator[locale] = locale;

    return accumulator;
  }, {} as T);
};

export const getDeviceLocale = (): Locale => {
  const deviceLocales = getLocales();

  const supportedLocalesMap = getSupportedLocalesMap();

  const supportedLocales = deviceLocales.reduce<Locale[]>((accumulator, deviceLocale) => {
    const languageCode = deviceLocale.languageCode as Locale;

    const supportedLocale = supportedLocalesMap[languageCode] as Locale;

    if (supportedLocale && !accumulator.includes(supportedLocale)) {
      return [...accumulator, supportedLocale];
    }

    return accumulator;
  }, []);

  return supportedLocales[0] || Locales.en;
};

export const getLocaleMessages = (locale?: Locale) => {
  switch (locale) {
    case Locales.ua:
      return translations.ua;

    case Locales.en:
    default:
      return translations.en;
  }
};

export const formatMessageExtractor = (...params: FormatMessageParams) => {
  return () => globalIntl?.formatMessage(...params);
};
