import React, { FC, useCallback, useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Button, Text } from 'react-native-paper';

import { LoggedOutStackScreenProps } from '@app/src/navigation/navigation';
import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isMobile, isWeb } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const logoIcon = require('@assets/logo.png');

interface LoginProps extends LoggedOutStackScreenProps<'Start'> {}

const Start: FC<LoginProps> = ({ navigation }) => {
  const { t } = useLocalize();

  const handleLoginOrSignIn = useCallback(
    (link: 'LogIn' | 'SignUp') => {
      navigation.navigate(link);
    },
    [navigation]
  );

  const returnButtons = useMemo(() => {
    return (
      <View
        style={stylesByPlatform({ webStyle: styles.centerWeb, mobileStyle: { width: '100%' } })}
      >
        <Button
          mode="contained"
          onPress={() => handleLoginOrSignIn('SignUp')}
          buttonColor={colors.black}
          style={styles.button}
          labelStyle={styles.buttonText}
        >
          {t('login.button.signup')}
        </Button>

        <Spacer height={8} />

        <Button
          mode="contained"
          onPress={() => handleLoginOrSignIn('LogIn')}
          buttonColor={colors.purple}
          textColor={colors.black}
          style={styles.button}
          labelStyle={styles.buttonText}
        >
          {t('login.button.login')}
        </Button>

        <Spacer height={32} />
      </View>
    );
  }, [handleLoginOrSignIn, t]);

  return (
    <View
      style={[
        sharedStyles.spaceBetween,
        sharedStyles.flex,
        sharedStyles.paddingHorizontal16,
        sharedStyles.backgroundColor,
        stylesByPlatform({ webStyle: sharedStyles.center }),
      ]}
    >
      <View />

      <View style={[sharedStyles.center, stylesByPlatform({ webStyle: styles.centerWeb })]}>
        <Image source={logoIcon} style={styles.logo} />

        <Spacer height={32} />

        <Text style={{ color: colors.black }}>Your one and only legal advisor</Text>

        {isWeb ? <Spacer height={32} /> : null}

        {isWeb ? returnButtons : null}
      </View>

      {isMobile ? returnButtons : <View />}
    </View>
  );
};

const styles = StyleSheet.create({
  centerWeb: { width: 343 },
  logo: { width: 179, height: 42 },
  button: { height: 56, borderRadius: 100 },
  buttonText: { fontSize: 16, paddingVertical: 8 },
});

export default Start;
