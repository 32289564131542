import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

const UserContext = createContext<any>(null);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState(null);

  const handleSetUser = useCallback((user) => {
    setUser(user);
  }, []);

  return <UserContext.Provider value={{ user, handleSetUser }}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
