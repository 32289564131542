import { IntlFormatters, useIntl } from 'react-intl';

import { Locale, translations } from '@core/locales';

type TranslationsDict = typeof translations;

type ID = keyof TranslationsDict[Locale];
type Values = Parameters<IntlFormatters<string>['formatMessage']>['1'];

export const useLocalize = () => {
  const { formatMessage, locale } = useIntl();

  const t = (id: ID, values?: Values) => {
    return formatMessage({ id, defaultMessage: `'${id}' missing in ${locale}` }, values) as string;
  };

  return { t, locale };
};

export const useLocale = () => {
  const { locale, defaultLocale } = useIntl();
  return (locale || defaultLocale || 'en') as Locale;
};
