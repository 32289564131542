/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useRef, useState } from 'react';
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Button, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { isWebMobile, screenWidth } from '@core/constants';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Header from './components/Header';

const cardIcon = require('@assets/LandingPage/card.png');
const frame1Icon = require('@assets/LandingPage/frame1.png');
const frame2Icon = require('@assets/LandingPage/frame2.png');
const frame3Icon = require('@assets/LandingPage/frame3.png');
const frame5Icon = require('@assets/LandingPage/frame5.png');
const frame6Icon = require('@assets/LandingPage/frame6.png');
const icon1 = require('@assets/LandingPage/icon1.png');
const icon2 = require('@assets/LandingPage/icon2.png');
const icon3 = require('@assets/LandingPage/icon3.png');
const icon4 = require('@assets/LandingPage/icon4.png');
const joinIcon = require('@assets/LandingPage/join.png');

const LandingPage = () => {
  const navigation = useNavigation();
  const scrollViewRef = useRef<ScrollView>(null);
  const [AboutY, setAboutY] = useState<number>(0);
  const [FAQY, setFAQY] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleJoin = useCallback(() => {
    navigation.navigate('LoggedOutStack');
  }, [navigation]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.white }}>
      <ScrollView ref={scrollViewRef} scrollEnabled={!isOpen}>
        <Header
          scrollViewRef={scrollViewRef}
          AboutY={AboutY}
          FAQY={FAQY}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />

        <View style={stylesByPlatform({ webStyle: styles.body, mobileStyle: styles.bodyMobile })}>
          <View
            style={[
              stylesByPlatform({ webStyle: sharedStyles.rowBetween }),
              sharedStyles.alignCenter,
            ]}
          >
            <View
              style={stylesByPlatform({
                webStyle: styles.block1,
                mobileStyle: styles.block1Mobile,
              })}
            >
              <Text
                style={stylesByPlatform({
                  webStyle: styles.textTitle,
                  mobileStyle: styles.textTitleMobile,
                })}
              >{`Unlock the power of `}

                <Text
                  style={[
                    stylesByPlatform({
                      mobileStyle: styles.textTitleMobile,
                      webStyle: styles.textTitle,
                    }),
                    styles.gradient,
                  ]}
                >
                  AI-driven
                </Text>{` legal guidance`}
              </Text>

              <Spacer height={16} />

              <Text style={styles.description}>
                Our cutting-edge platform is designed to provide you with accurate and reliable
                answers to your legal questions, quickly and efficiently
              </Text>

              <Spacer height={16} />

              <TouchableOpacity onPress={handleJoin}>
                <Image
                  source={joinIcon}
                  style={stylesByPlatform({
                    webStyle: styles.joinIcon,
                    mobileStyle: styles.joinIconMobile,
                  })}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>

            <Image
              source={frame1Icon}
              style={stylesByPlatform({
                mobileStyle: styles.frame1IconMobile,
                webStyle: styles.frame1Icon,
              })}
              resizeMode="contain"
            />
          </View>

          <View
            style={sharedStyles.center}
            onLayout={(event) => setAboutY(event.nativeEvent.layout.y)}
          >
            <View
              style={stylesByPlatform({
                mobileStyle: styles.centerBlock2Mobile,
                webStyle: styles.centerBlock2,
              })}
            >
              <Text style={styles.title2}>Why Genivisor?</Text>

              <Spacer height={8} />

              <Text style={[styles.description, sharedStyles.textCenter]}>
                Empowering you with instant access to a vast repository of legal knowledge,
                Genivisor is tailored to simplify the complexities of the legal world.
              </Text>
            </View>

            <Spacer height={24} />

            <View style={stylesByPlatform({ webStyle: sharedStyles.rowBetween })}>
              <ImageBackground
                source={cardIcon}
                style={stylesByPlatform({ webStyle: styles.card, mobileStyle: styles.cardMobile })}
                resizeMode="stretch"
              >
                <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
                  <Image source={icon1} style={styles.icon} />

                  <Spacer width={16} height={16} />

                  <Text
                    style={[styles.title3, stylesByPlatform({ mobileStyle: sharedStyles.flex })]}
                  >
                    Instant Access to Reliable Legal Guidance
                  </Text>
                </View>

                <Spacer height={8} />

                <Text style={styles.description}>
                  Genivisor offers users the ability to access accurate and reliable legal guidance
                  instantly. Users can receive quick and precise answers to their legal queries,
                  saving time and effort in seeking out complex legal information.
                </Text>
              </ImageBackground>

              <Spacer width={24} height={16} />

              <ImageBackground
                source={cardIcon}
                style={stylesByPlatform({ webStyle: styles.card, mobileStyle: styles.cardMobile })}
                resizeMode="stretch"
              >
                <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
                  <Image source={icon2} style={styles.icon} />

                  <Spacer width={16} />

                  <Text
                    style={[styles.title3, stylesByPlatform({ mobileStyle: sharedStyles.flex })]}
                  >
                    Tailored and Comprehensive Insights
                  </Text>
                </View>

                <Spacer height={8} />

                <Text style={styles.description}>
                  Genivisor is equipped with a vast repository of legal knowledge, ensuring that
                  users receive tailored and comprehensive insights specific to their legal needs.
                </Text>
              </ImageBackground>

              <Spacer width={24} height={16} />

              <ImageBackground
                source={cardIcon}
                style={stylesByPlatform({ webStyle: styles.card, mobileStyle: styles.cardMobile })}
                resizeMode="stretch"
              >
                <View style={[sharedStyles.row, sharedStyles.alignCenter]}>
                  <Image source={icon3} style={styles.icon} />

                  <Spacer width={16} />

                  <Text
                    style={[styles.title3, stylesByPlatform({ mobileStyle: sharedStyles.flex })]}
                  >
                    User-Friendly Interface for Hassle-Free Assistance
                  </Text>
                </View>

                <Spacer height={8} />

                <Text style={styles.description}>
                  Genivisor's user-friendly interface makes navigating the world of law effortless.
                  Users can interact with the platform seamlessly, without the need for specialized
                  legal knowledge.
                </Text>
              </ImageBackground>
            </View>
          </View>

          <Spacer height={42} />

          <View
            style={[
              stylesByPlatform({ webStyle: sharedStyles.rowBetween }),
              sharedStyles.alignCenter,
            ]}
          >
            <View
              style={stylesByPlatform({
                mobileStyle: styles.block2Mobile,
                webStyle: styles.block2,
              })}
            >
              <Text style={styles.title2}>How it Works</Text>

              <Spacer height={8} />

              <Text style={styles.description}>
                Simply type in your legal query, and Genivisor's advanced AI technology will analyze
                your question and provide you with comprehensive, jargon-free answers.
              </Text>

              <Spacer height={8} />

              <Text style={styles.description}>
                Our system is trained on a diverse set of legal contexts, ensuring that you receive
                relevant and up-to-date information to guide your decision-making process.
              </Text>

              <Spacer height={8} />

              <View
                style={stylesByPlatform({
                  mobileStyle: styles.buttonContainerMobile,
                  webStyle: sharedStyles.row,
                })}
              >
                <Button
                  mode="contained"
                  onPress={handleJoin}
                  buttonColor={colors.black}
                  labelStyle={sharedStyles.center}
                  style={styles.button}
                >
                  <Text style={[styles.buttonText, { color: colors.white, paddingTop: 2 }]}>
                    Try for Free
                  </Text>
                </Button>

                {isWebMobile ? <Spacer height={12} /> : <Spacer width={18} />}

                <Button
                  onPress={handleJoin}
                  mode={'outlined'}
                  textColor={colors.black}
                  buttonColor={colors.purple4}
                  style={[styles.button, { borderColor: colors.purple3 }]}
                >
                  <View style={[sharedStyles.center, sharedStyles.row]}>
                    <Text style={styles.buttonText}>Watch Tutorial</Text>

                    <Spacer width={12} />

                    <Image source={icon4} style={styles.icon4} />
                  </View>
                </Button>
              </View>
            </View>

            {isWebMobile ? <Spacer height={24} /> : null}

            <Image
              source={frame2Icon}
              style={stylesByPlatform({
                mobileStyle: styles.frame2IconMobile,
                webStyle: styles.frame2Icon,
              })}
            />
          </View>

          <Spacer height={24} />

          <View
            style={[
              stylesByPlatform({ webStyle: sharedStyles.rowBetween }),
              sharedStyles.alignCenter,
            ]}
          >
            {isWebMobile ? null : (
              <Image
                source={frame3Icon}
                style={stylesByPlatform({
                  mobileStyle: styles.frame3IconMobile,
                  webStyle: styles.frame3Icon,
                })}
              />
            )}

            <View
              style={stylesByPlatform({
                mobileStyle: styles.block2Mobile,
                webStyle: styles.block2,
              })}
            >
              <Text style={styles.title2}>Your Trusted Legal Companion</Text>

              <Spacer height={8} />

              <Text style={styles.description}>
                With Genivisor, legal complexities are no longer a barrier. Say goodbye to endless
                searches and complicated jargon.
              </Text>

              <Spacer height={8} />

              <Text style={styles.description}>
                Our user-friendly interface and intuitive design make accessing legal expertise easy
                and hassle-free.
              </Text>

              <Spacer height={8} />

              <View style={sharedStyles.row}>
                <Button
                  mode="contained"
                  onPress={handleJoin}
                  buttonColor={colors.black}
                  labelStyle={sharedStyles.center}
                  style={styles.button}
                >
                  <Text style={[styles.buttonText, { color: colors.white }]}>Try for Free</Text>
                </Button>
              </View>
            </View>

            {isWebMobile ? (
              <Image
                source={frame3Icon}
                style={stylesByPlatform({
                  mobileStyle: styles.frame3IconMobile,
                  webStyle: styles.frame3Icon,
                })}
              />
            ) : null}
          </View>

          <Spacer height={24} />

          <FAQ setFAQY={setFAQY} />

          <Spacer height={40} />

          <View style={stylesByPlatform({ webStyle: sharedStyles.rowCenterBetween })}>
            {isWebMobile ? null : <Image source={frame5Icon} style={styles.frame5Icon} />}

            <View
              style={stylesByPlatform({
                mobileStyle: styles.centerBlock3Mobile,
                webStyle: styles.centerBlock3,
              })}
            >
              <Text style={[sharedStyles.textCenter, styles.title2]}>
                Start Exploring with Genivisor Today
              </Text>

              <Spacer height={8} />

              <Text style={[styles.description, sharedStyles.textCenter]}>
                Join our growing community of users who have discovered the convenience and
                reliability of having a legal companion at their fingertips
              </Text>

              <Spacer height={8} />

              <Button
                mode="contained"
                onPress={handleJoin}
                buttonColor={colors.black}
                labelStyle={sharedStyles.center}
                style={styles.button}
              >
                <Text style={[styles.buttonText, { color: colors.white, paddingTop: 2 }]}>
                  Try Genivisor
                </Text>
              </Button>
            </View>

            {isWebMobile ? null : <Image source={frame6Icon} style={styles.frame6Icon} />}
          </View>

          {isWebMobile ? <Spacer height={32} /> : null}
        </View>

        <Footer />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  body: { flex: 1, backgroundColor: colors.white, paddingHorizontal: 80 },
  bodyMobile: { flex: 1, backgroundColor: colors.white, paddingHorizontal: 16 },
  frame1Icon: { width: 705, height: 666 },
  frame1IconMobile: { width: screenWidth + 80, height: 378 },
  frame2Icon: { width: 737, height: 518 },
  frame2IconMobile: { width: screenWidth - 32, height: 252 },
  frame3Icon: { width: 494, height: 546 },
  frame3IconMobile: { width: screenWidth - 32, height: 380 },
  frame5Icon: { width: 470, height: 220, marginLeft: -100 },
  frame6Icon: { width: 486, height: 574, marginLeft: 100 },
  joinIcon: { width: 417, height: 64 },
  joinIconMobile: { width: screenWidth - 32, height: 64 },
  block1: { width: 468 },
  block1Mobile: { flex: 1 },
  block2: { width: 529 },
  block2Mobile: { flex: 1 },
  textTitle: { fontSize: 50, fontWeight: '700', color: colors.black },
  textTitleMobile: { fontSize: 40, fontWeight: '700', color: colors.black },
  gradient: { color: colors.purple2 },
  description: { color: colors.black, fontSize: 16, fontWeight: '400', lineHeight: 24 },
  title2: { color: colors.black, fontSize: 32, fontWeight: '700' },
  title3: { color: colors.black, fontSize: 24, fontWeight: '700' },
  centerBlock2: { width: 629, justifyContent: 'center', alignItems: 'center' },
  centerBlock2Mobile: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  centerBlock3: { width: 629, justifyContent: 'center', alignItems: 'center', marginLeft: 80 },
  centerBlock3Mobile: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  card: { width: 410, padding: 16, height: 216 },
  cardMobile: { width: screenWidth - 32, padding: 16 },
  icon: { width: 40, height: 40 },
  icon4: { width: 24, height: 24 },
  button: { borderRadius: 100, height: 56, alignItems: 'center', justifyContent: 'center' },
  buttonText: { fontSize: 16, fontWeight: '600', color: colors.black },
  buttonContainerMobile: { width: '70%' },
});

export default LandingPage;
