import React, { FC, useCallback, useMemo, useState } from 'react';
import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';

import moment from 'moment';
import { ActivityIndicator, Button, Modal, Portal, Text } from 'react-native-paper';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import Spacer from '@components/Spacer';
import colors from '@core/colors/colors';
import { screenWidth } from '@core/constants';
import { useLocalize } from '@core/hooks/localize';
import radii from '@core/styles/radii';
import { sharedStyles } from '@core/styles/sharedStyles';
import { stylesByPlatform } from '@core/utils';

const deleteGrayIcon = require('@assets/delete-gray.png');
const deleteIcon = require('@assets/delete.png');

const today = moment();

const yesterday = moment().add(-1, 'days');

interface HistoryItem {
  id: string;
  name: string;
  date: number;
}

interface ListItemProps {
  item: HistoryItem;
  arr: HistoryItem[];
  index: number;
  onPressItem: (id: string) => void;
  onDeleteItem: (id: string) => void;
}

interface ItemInterface {
  onPress: () => void;
  animatedStylesRotate: {
    transform: {
      rotate: string;
    }[];
  };
  isLoading: boolean;
}

const RenderRightActions = ({ onPress, animatedStylesRotate, isLoading }: ItemInterface) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleInHover = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleOutHover = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Pressable onHoverIn={handleInHover} onHoverOut={handleOutHover}>
      <TouchableOpacity
        style={[sharedStyles.center, isHover ? styles.rightActions : styles.outHoverRightActions]}
        onPress={onPress}
        disabled={isLoading}
      >
        {isLoading ? (
          <ActivityIndicator color={colors.white} size={24} />
        ) : (
          <Animated.Image
            source={isHover ? deleteIcon : deleteGrayIcon}
            style={[styles.icon, animatedStylesRotate]}
          />
        )}
      </TouchableOpacity>
    </Pressable>
  );
};

const ListItemWeb: FC<ListItemProps> = ({ item, arr, index, onPressItem, onDeleteItem }) => {
  const { t } = useLocalize();
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { name, date } = item;
  const offsetRotate = useSharedValue(0);

  const animatedStylesRotate = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: offsetRotate.value + 'deg' }],
    };
  });

  const handleOpenModal = useCallback(() => {
    offsetRotate.value = withTiming(offsetRotate.value + 10);
    setTimeout(() => {
      offsetRotate.value = withTiming(offsetRotate.value - 20);
    }, 200);
    setTimeout(() => {
      offsetRotate.value = withTiming(offsetRotate.value + 20);
    }, 400);
    setTimeout(() => {
      offsetRotate.value = withTiming(0);
    }, 600);
    setVisible(true);
  }, [offsetRotate]);

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  const handleDeleteChat = useCallback(() => {
    handleCloseModal();
    onDeleteItem(item.id);
    // setIsLoading(true);
    // try {
    // } catch (error) {
    //   console.log(error);
    // }
    // setIsLoading(false);
  }, [handleCloseModal, item.id, onDeleteItem]);

  const returnDate = useMemo(() => {
    let newDate = '';

    if (moment(date).isSame(today.valueOf(), 'day')) {
      newDate = 'Today';
    } else if (moment(date).isSame(yesterday.valueOf(), 'day')) {
      newDate = 'Yesterday';
    } else {
      newDate = moment(date).format('DD/MM/YY HH:mm');
    }
    return (
      <View>
        <Text style={styles.time}>{newDate.toUpperCase()}</Text>

        <Spacer height={8} />
      </View>
    );
  }, [date]);

  const isFirstDate = useMemo(() => {
    const findIndex = arr.findIndex((x) => moment(x.date).isSame(date, 'day'));
    if (findIndex === index) {
      return true;
    }
    return false;
  }, [arr, date, index]);

  return (
    <View>
      {isFirstDate ? returnDate : null}

      <TouchableOpacity style={sharedStyles.row} onPress={() => onPressItem(item.id)}>
        <View style={styles.swipeable}>
          <Text style={styles.text}>{name}</Text>
        </View>

        <RenderRightActions
          onPress={handleOpenModal}
          animatedStylesRotate={animatedStylesRotate}
          isLoading={isLoading}
        />
      </TouchableOpacity>

      <Portal>
        <Modal visible={visible} onDismiss={handleCloseModal}>
          <View
            style={stylesByPlatform({
              mobileStyle: sharedStyles.paddingHorizontal16,
              webStyle: styles.containerWeb,
            })}
          >
            <View style={styles.containerStyle}>
              <Text variant={'titleMedium'} style={styles.title}>
                {t('history.modal.title')}
              </Text>

              <Spacer height={12} />

              <View style={styles.buttonContainer}>
                <Button
                  textColor={colors.black}
                  labelStyle={sharedStyles.center}
                  onPress={handleCloseModal}
                >
                  <Text style={styles.buttonText}>{t('history.modal.cancel')}</Text>
                </Button>

                <Spacer width={16} />

                <Button
                  mode="contained"
                  buttonColor={colors.red}
                  onPress={handleDeleteChat}
                  loading={isLoading}
                >
                  <Text style={[{ color: colors.white }, styles.buttonText]}>
                    {t('history.modal.delete')}
                  </Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  swipeable: {
    backgroundColor: colors.purple4,
    flex: 1,
    height: 56,
    borderTopLeftRadius: radii.button,
    borderBottomLeftRadius: radii.button,
    padding: 16,
  },
  icon: { width: 24, height: 24 },
  rightActions: {
    backgroundColor: colors.red,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: 56,
    height: 56,
  },
  outHoverRightActions: {
    backgroundColor: colors.purple4,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: 56,
    height: 56,
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    color: colors.black,
  },
  buttonContainer: { flexDirection: 'row', justifyContent: 'flex-end' },
  buttonText: { fontSize: 16, fontWeight: '600' },
  containerStyle: { backgroundColor: colors.white, padding: 20, borderRadius: radii.button },
  title: { fontSize: 18, fontWeight: '700' },
  time: { fontWeight: '600', color: colors.purple5, fontSize: 12, textAlign: 'center' },
  containerWeb: { padding: (screenWidth - 650) / 2 },
});

export default ListItemWeb;
